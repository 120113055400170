import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FactToCheck, ProposalProductCardModes, SimpleProductsDynamicFieldsProps } from "modules/desking/types";
import { StringField } from "./StringField";
import { NumericField } from "./NumericField";
import { BooleanField } from "./BooleanField";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";

interface Props {
  proposalProduct: ProposalProduct;
  facts: Record<string, any>;
  setFacts: any;
  factsToCheck: FactToCheck[];
  mode: ProposalProductCardModes;
  productConfigurationRulesEngine: any;
}

export const DynamicFields = ({
  proposalProduct,
  factsToCheck,
  productConfigurationRulesEngine,
  facts,
  setFacts,
  mode,
}: Props) => {
  const classes = useStyles();

  const componentsByType: Record<FactToCheck["type"], FC<SimpleProductsDynamicFieldsProps>> = {
    string: StringField,
    numeric: NumericField,
    boolean: BooleanField,
  };

  return (
    <Box className={classes.fields}>
      {factsToCheck
        .filter(fact => fact.factKey !== "DEAL_SIZE")
        .map(fact => {
          const Field = componentsByType[fact.type];

          return (
            <Field
              proposalProduct={proposalProduct}
              key={fact.factKey}
              fact={fact}
              facts={facts}
              setFacts={setFacts}
              disabled={false}
              productConfigurationRulesEngine={productConfigurationRulesEngine}
            />
          );
        })}
    </Box>
  );
};

const useStyles = makeStyles({
  fields: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
});
