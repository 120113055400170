import React, { Fragment, ReactNode } from "react";
import { useSelector } from "react-redux";
import { ModalsKeys } from "../../../global";
import { modalsSelectors } from "../../../redux/modalsReducer";

interface Props {
  modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>>;
}

export const Modals = ({ modalsWithKeys }: Props) => {
  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];

  return (
    <>
      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};
