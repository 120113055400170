import React from "react";
import { DndProvider } from "react-dnd";
import { Box } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ModalsKeys } from "../../../../../global";
import { useModal } from "../../../../../hooks/useModal";
import {
  CustomerSection,
  EquipmentSection,
  MenuBuilderSection,
  SaleDetailsSection,
  SendViaSection,
  StructureSection,
} from "./sections";
import { deskingActions, deskingSelectors } from "../../../model";
import { FinanceProgramFormValues, FormSections, UserActionsEvents } from "../../../types";

export const PanelForm = (props: Partial<UserActionsEvents>) => {
  const { resetField } = useFormContext<FinanceProgramFormValues>();

  const dispatch = useDispatch();

  const { handleOpen } = useModal(ModalsKeys.Confirmation);

  const sectionsCollapseStatuses = useSelector((state: any) =>
    deskingSelectors.sectionsStatuses(state, [FormSections.MenuBuilder])
  );

  const handleUpdateCollapse = (section: FormSections, nextValue: boolean) => {
    dispatch(
      deskingActions.updateSectionIsOpenStatus({
        section,
        isOpen: nextValue,
      })
    );
  };

  const handleClearStructureAndMenuOptions = () => {
    handleOpen({
      title: "Are you sure you want to reset the proposal builder?",
      message: "This action will remove all configurations from the Finance Program and Menu.",
      onConfirm: () => {
        resetField("financeQuote");
        dispatch(deskingActions.resetStructureAndMenuOptions());
      },
    });
  };

  return (
    <>
      <Box className="row">
        <CustomerSection sectionName={FormSections.Customer} />

        <SaleDetailsSection sectionName={FormSections.SaleDetails} />
      </Box>

      <EquipmentSection sectionName={FormSections.Equipment} onUpdateCollapse={handleUpdateCollapse} {...props} />

      <Box className="row">
        <DndProvider backend={HTML5Backend}>
          {!sectionsCollapseStatuses[FormSections.MenuBuilder] && (
            <StructureSection
              sectionName={FormSections.Structure}
              onUpdateCollapse={handleUpdateCollapse}
              onClearStructureAndMenuOptions={handleClearStructureAndMenuOptions}
            />
          )}

          <MenuBuilderSection
            sectionName={FormSections.MenuBuilder}
            onUpdateCollapse={handleUpdateCollapse}
            onClearStructureAndMenuOptions={handleClearStructureAndMenuOptions}
          />
        </DndProvider>
      </Box>

      <SendViaSection sectionName={FormSections.SendVia} />
    </>
  );
};
