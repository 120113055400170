import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Rate } from "@trnsact/ntp-sdk";
import { Addons } from "./Addons";
import { Fields } from "./Fields";
import {
  CommonMenuPriceValues,
  MarkupType,
  ProposalProductCardModes,
  ProposalProductCardProps,
} from "../../../../../types";
import { vendorsLogoByType } from "../../../../../constants";
import { ProposalCardContainer } from "../cardContainer/ProposalCardContainer";
import { useSelector } from "react-redux";
import { deskingSelectors } from "modules/desking/model/selectors";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { logError } from "../../../../../../../utils/logger";

export const Card = ({
  mode,
  product,
  menuName = "",
  type = "simple",
  updateProductConfiguration,
  proposalProductConfiguration,
}: ProposalProductCardProps) => {
  const [forcePriceValue, setForcePriceValue] = useState<CommonMenuPriceValues>({
    retailCost: product.retailCost,
    cost: product.cost,
    markup: product.markup,
  });
  const products = useSelector(deskingSelectors.proposalsProducts) as ProposalProduct[];

  const [configuration, setConfiguration] = useState<Record<"addons" | "coverage" | "deductible", any>>({
    addons: [],
    coverage: null,
    deductible: null,
  });

  useEffect(() => {
    if (product?.aftermarketProduct?.config?.criteriaValues![0]) {
      setConfiguration(product.aftermarketProduct.config.criteriaValues[0]);
    }
  }, [])

  useEffect(() => {
    if (type !== "forEdit" || !proposalProductConfiguration) return;

    const config = proposalProductConfiguration.configuration;
    if (config) {
      setConfiguration(config);
    }
  }, [proposalProductConfiguration]);

  const [checkedAddons, setCheckedAddons] = useState<{ [key: string]: { checked: boolean; lossCode: any } }>({});

  useEffect(() => {
    if (updateProductConfiguration) {
      updateProductConfiguration(product.proposalProductId, menuName, {
        cost: forcePriceValue.cost,
        retailCost: forcePriceValue.retailCost,
        markup: {
          markup: forcePriceValue.markup?.markup,
          type: forcePriceValue.markup?.type,
        },
      });
    }
  }, [forcePriceValue]);

  const { deductibleOptions, mileageOptions, addonsOptions } = useMemo(() => {
    let data: { API_RESPONSE_DATA: Rate } =
      product?.aftermarketProduct?.criteria?.[0]?.event?.params?.AFTERMARKET_PRODUCT_VENDOR_API_DATA;

    if (!data) {
      const productFromApi = products.find(p => p.proposalProductId === product.proposalProductId);
      if (productFromApi) {
        data = productFromApi.aftermarketProduct?.criteria?.[0]?.event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA;
      }
    }

    const accessKey = `${configuration?.coverage?.termMonths}-${configuration?.coverage?.termOdometer}`;

    return {
      deductibleOptions: _.uniqBy(data?.API_RESPONSE_DATA.terms, term => term.deductible.amount).map(
        term => term.deductible.amount
      ),
      mileageOptions:
        configuration?.deductible || configuration?.deductible === 0
          ? data?.API_RESPONSE_DATA.terms.filter(term => term.deductible.amount === configuration?.deductible)
          : [],
      addonsOptions: data?.API_RESPONSE_DATA.terms.reduce<any>((acc, data) => {
        const key = `${data.termMonths}-${data.termOdometer}`;

        acc[key] = data;

        return acc;
      }, {})?.[accessKey],
    };
  }, [product, configuration]);

  const handleProductConfigurationUpdate = (nextConfiguration: any) => {
    if (updateProductConfiguration) {
      updateProductConfiguration(product.proposalProductId, menuName, {
        configuration: nextConfiguration,
        addons: nextConfiguration.addons,
      });
    }
  };

  const handleDeductibleChange = (selectedAmount: number) => {
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        coverage: null,
        deductible: selectedAmount,
      };

      if (type === "forEdit") handleProductConfigurationUpdate(nextConfiguration);
      return nextConfiguration;
    });
  };

  const handleMileageChange = (selectedTerm: any) => {
    const addonsCost = configuration?.addons.reduce((acc: number, addon: any) => acc + addon.cost, 0);

    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        coverage: selectedTerm,
      };

      const markupToUse = proposalProductConfiguration?.markup ?? forcePriceValue.markup;

      let retailCost = selectedTerm?.dealerCost ?? 0;

      if (markupToUse) {
        if (markupToUse.type === MarkupType.Percentage) {
          retailCost = _.round((selectedTerm?.dealerCost + addonsCost) * (1 + markupToUse.markup / 100), 2);
        } else {
          retailCost = selectedTerm?.dealerCost + addonsCost + markupToUse.markup;
        }
      }

      setForcePriceValue({
        retailCost,
        cost: (selectedTerm?.dealerCost ?? 0) + addonsCost,
        markup: markupToUse ? markupToUse : { type: MarkupType.Percentage, markup: 0 },
      });

      if (type === "forEdit") handleProductConfigurationUpdate(nextConfiguration);

      return nextConfiguration;
    });
  };

  const handleCheckboxChange = (lossCode: any, value: boolean) => {
    if (!configuration?.coverage) return;

    setCheckedAddons(prev => ({
      ...prev,
      [lossCode.title]: {
        checked: value,
        lossCode,
      },
    }));

    setConfiguration(prev => {
      const nextAddons = !value
        ? prev.addons.filter((addon: any) => addon.title !== lossCode.title)
        : [...prev.addons, lossCode];

      const nextConfiguration = {
        ...prev,
        addons: nextAddons,
      };

      const addonsCost = nextAddons.reduce((acc: number, addon: any) => acc + addon.cost, 0);

      const newCost = (configuration?.coverage.dealerCost ?? 0) + addonsCost;

      let newRetailCost = newCost;

      const markupToUse = forcePriceValue.markup ?? proposalProductConfiguration?.markup;

      if (markupToUse) {
        if (markupToUse.type === MarkupType.Percentage) {
          newRetailCost = _.round(newRetailCost * (1 + markupToUse.markup / 100), 2);
        } else {
          newRetailCost = newRetailCost + markupToUse.markup;
        }
      }

      setForcePriceValue({
        cost: newCost,
        markup: markupToUse,
        retailCost: newRetailCost,
      });

      if (type === "forEdit") handleProductConfigurationUpdate(nextConfiguration);

      return nextConfiguration;
    });
  };

  const productWithAddons = useMemo(() => {
    if (configuration?.addons) {
      try {
        // @ts-expect-error - configuration exists
        const removedAddon = product.configuration?.removedAddon;
        if (removedAddon && checkedAddons[removedAddon].checked === true) {
          handleCheckboxChange(checkedAddons[removedAddon].lossCode, false);
          // @ts-expect-error - configuration exists
          product.configuration.removedAddon = null;
        }
      } catch (error) {
        logError(error);
      }
    }
    return {
      ...product,
    };
  }, [product, configuration]);

  return (
    <ProposalCardContainer
      mode={mode}
      type={type}
      menuName={menuName}
      configuration={configuration}
      logo={vendorsLogoByType["NTP"]}
      forcePriceValue={forcePriceValue}
      setForcePriceValue={setForcePriceValue}
      proposalProduct={productWithAddons}
      updateProductConfiguration={updateProductConfiguration}
    >
      <Fields
        configuration={configuration}
        uniqueDeductibles={deductibleOptions}
        filteredMileageOptions={mileageOptions}
        handleMileageChange={handleMileageChange}
        handleDeductibleChange={handleDeductibleChange}
        disableAllFields={false}
      />

      {addonsOptions && (
        <Addons
          configuration={configuration}
          addonsOptions={addonsOptions}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
    </ProposalCardContainer>
  );
};
