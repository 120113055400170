import React, { Fragment, ReactNode } from "react";
import { useSelector } from "react-redux";
import { LoadModal } from "./Load/LoadModal";
import { ModalsKeys } from "../../../global";
import { modalsSelectors } from "../../../redux/modalsReducer";
import { ConfirmationModal } from "./Confirmation/ConfirmationModal";
import { CreateEquipmentModalOld } from "./CreateEquipment/CreateEquipmentModalOld";

export const CommonModals = () => {
  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.Load]: <LoadModal />,
    [ModalsKeys.Confirmation]: <ConfirmationModal />,
    [ModalsKeys.CreateEquipment]: <CreateEquipmentModalOld />,
  };

  return (
    <>
      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};
