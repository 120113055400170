import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { AftermarketProductType, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { EmptyList } from "./EmptyList";
import { ProductLogo } from "./ProductLogo";
import { ModalsKeys } from "../../../../../global";
import { useModal } from "../../../../../hooks/useModal";
import { ProposalProductsFilters } from "../../components";
import { FormCheckbox } from "../../../../../components/form";
import { ProductCatalogDetails } from "../ProductCatalog/ProductCatalogDetails";

export const ProposalProductsDialog = () => {
  const classes = useStyles();

  const { isOpen, data, handleClose } = useModal(ModalsKeys.ProposalProductsDialog);

  const [proposalProducts, setProposalProducts] = useState<ProposalProduct[]>(data?.products ?? []);
  const [selectedProductsIds, setSelectedProductsIds] = useState<string[]>([]);

  const handelConfirm = () => {
    const selectedProducts = proposalProducts.filter(({ proposalProductId }) =>
      selectedProductsIds.includes(proposalProductId)
    );
    data.onConfirm && data.onConfirm(selectedProducts);
    handleClose();
  };

  useEffect(() => {
    setSelectedProductsIds(data.selectedProposalsProductsIds);

    return () => setSelectedProductsIds([]);
  }, [data.selectedProposalsProductsIds]);

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <Typography component="span" variant="h6">
            Product
          </Typography>

          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box className={classes.dialogContent}>
          <ProposalProductsFilters products={data?.products} onFiltered={setProposalProducts} />

          <Typography component="span" variant="subtitle2">
            Products
          </Typography>

          <Box className="products">
            {!!proposalProducts.length ? (
              proposalProducts
                .filter(pp => pp.aftermarketProduct?.productType === AftermarketProductType.Product)
                .map(product => (
                  <Box key={product.proposalProductId} className="product">
                    <Box>
                      <ProductLogo product={product} />
                    </Box>

                    <FormCheckbox
                      label={product.title}
                      checked={selectedProductsIds.includes(product.proposalProductId)}
                      onChange={() => {
                        setSelectedProductsIds(prev => {
                          if (!selectedProductsIds.includes(product.proposalProductId)) {
                            return [...prev, product.proposalProductId];
                          }

                          return prev.filter(id => id !== product.proposalProductId);
                        });
                      }}
                    />

                    <Tooltip
                      interactive
                      classes={{ popper: classes.infoTooltip }}
                      title={<ProductCatalogDetails proposalProduct={product} key={product.proposalProductId} />}
                    >
                      <InfoIcon color="primary" style={{ marginLeft: "auto" }} />
                    </Tooltip>
                  </Box>
                ))
            ) : (
              <EmptyList />
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button size="small" color="primary" variant="contained" onClick={handelConfirm}>
          Confirm
        </Button>

        <Button size="small" color="primary" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  dialogContent: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",

    "& .products": {
      gap: "8px",
      height: "300px",
      display: "flex",
      overflow: "auto",
      flexDirection: "column",

      "& .product": {
        gap: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
  },
  formCheckbox: {
    flexGrow: 1,
    textAlign: "left",
  },
  infoTooltip: {
    "& .MuiTooltip-tooltip": {
      background: "white",
      overflow: "auto",
      maxWidth: 450,
      maxHeight: 600,
      color: "#000000de",
    },
  },
});
