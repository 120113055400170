import React from "react";
import clsx from "clsx";
import { blue, grey } from "@material-ui/core/colors";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { formatCurrency } from "../../../../../utils";
import { ProposalSettings } from "./ProposalSettings";
import { OptionSummaryInfo } from "./OptionSummaryInfo";
import { OptionSummaryDetails } from "./OptionSummaryDetails";
import { MenuOptionProductsDescriptions } from "./MenuOptionProductsDescriptions";

interface Props {
  isSectionOpen?: boolean;
  menuOption: ProposalMenuOption;
}

export const MenuOptionDetail = ({ menuOption, isSectionOpen }: Props) => {
  const classes = useStyles({ isSectionOpen: isSectionOpen ?? true });

  const renderData = [
    {
      label: "Finance Profit",
      value: menuOption.payment?.financeProfit,
      formatter: (value: any) => formatCurrency(value ?? "0", true),
    },
    {
      label: "Dealer Markup (%)",
      value: menuOption.payment?.dealerMarkup,
      formatter: (value: any) => (value ? `${value}%` : "0.00%"),
    },
    {
      label: "Aftermarket Profit",
      value: (menuOption.payment as any)?.aftermarketProfit,
      formatter: (value: any) => formatCurrency(value ?? "0", true),
    },
    {
      label: "Customer Rate",
      value: menuOption.payment?.customerRate,
      formatter: (value: any) => (value ? `${value}%` : "0.00%"),
    },
    {
      label: "Product Cost",
      value: (menuOption.payment as any)?.productCost,
      formatter: (value: any) => formatCurrency(value ?? "0", true),
    },
    {
      label: "Total profit",
      value: (menuOption.payment as any)?.totalProfit,
      formatter: (value: any) => formatCurrency(value ?? "0", true),
    },
    {
      label: "Profit %",
      value: (menuOption.payment as any)?.profitPercentage,
      formatter: (value: any) => (value ? `${value}%` : "0%"),
    },
    {
      label: "Amount Due Today",
      value: (menuOption.payment as any)?.amountDueToday,
      formatter: (value: any) => formatCurrency(value ?? "0", true),
    },
  ];

  return (
    <Box className={classes.container}>
      <Box className={classes.containerWithAlert}>
        <Box className={clsx(classes.panel, { [classes.panelWithData]: !!menuOption })}>
          <Typography component="span" variant="subtitle2">
            Menu Option Detail
          </Typography>
        </Box>

        {!menuOption && (
          <Typography component="span" variant="body2" color="textSecondary">
            Click on Menu Options to see Option Summary
          </Typography>
        )}
      </Box>

      <Box className={classes.summary}>
        <Box className={classes.summaryData}>
          {renderData.map(({ value, label, formatter }) => (
            <OptionSummaryInfo key={label} label={label} value={value} formatter={formatter} />
          ))}
        </Box>

        {!!menuOption?.products?.length && <MenuOptionProductsDescriptions menuOption={menuOption} />}

        <OptionSummaryDetails menuOption={menuOption} />

        <ProposalSettings />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { isSectionOpen: boolean }>({
  container: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  containerWithAlert: {
    gap: "6px",
    display: "flex",
    flexDirection: "column",
  },
  panel: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: grey["A100"],
  },
  panelWithData: {
    backgroundColor: blue["50"],
  },
  summary: {
    gap: "6px",
    display: "flex",
    flexDirection: "column",

    "& .infoItem": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  summaryData: {
    gap: "6px",
    display: "grid",
    gridTemplateColumns: ({ isSectionOpen }) => (!isSectionOpen ? "1fr 1fr 1fr 1fr" : "repeat(8, 1fr)"),
  },
});
