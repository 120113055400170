import React from "react";
import { Box, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

interface Props {
  addon: any;
  primaryColor: string;
}

export const Addon = ({ addon, primaryColor }: Props) => {
  return (
    <Box key={addon.title}>
      <Box display="flex" padding={1}>
        <CheckCircleIcon style={{ color: primaryColor, marginRight: 4 }} />
        <Typography variant="subtitle2">{addon.title}</Typography>
      </Box>
    </Box>
  );
};
