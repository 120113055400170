import React, { useEffect, useState } from "react";
import clsx from "clsx";
import ReactTable, { Column, TableProps } from "react-table";
import { Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { usePagination } from "../../../../hooks/usePagination";
import { hubSearchFilterFoTable } from "../../lib/hubSearchFilters";
import { CustomPagination } from "../../../../components/Table/Pagination";

interface Props<TableData> {
  minRows?: number;
  loading?: boolean;
  minHeight?: string;
  tableData: TableData[];
  withPagination?: boolean;
  columns: Column<TableData>[];
}

export const TableView = <TableData extends any>({
  columns,
  loading,
  tableData,
  minRows = 5,
  minHeight = "",
  withPagination = true,
}: Props<TableData>) => {
  const classes = useStyles({ minHeight });

  const [filteredData, setFilteredData] = useState<TableData[]>([]);

  const paginationProps = usePagination(filteredData.length);

  useEffect(() => {
    setFilteredData(tableData);
    paginationProps.onPageChange(0);

    return () => {
      setFilteredData([]);
    };
  }, [tableData]);

  return (
    <ReactTable<TableData>
      minRows={minRows}
      loading={loading}
      columns={columns}
      showPaginationBottom={withPagination}
      data={loading ? [] : filteredData}
      className={clsx("-highlight", classes.table)}
      PaginationComponent={withPagination ? CustomPagination : undefined}
      onFilteredChange={newFiltering => {
        setFilteredData(hubSearchFilterFoTable(tableData, newFiltering));
        paginationProps.onPageChange(0);
      }}
      loadingText={
        <Typography component="span" variant="h6">
          Proposals loading...
        </Typography>
      }
      noDataText={
        !loading && (
          <Typography component="span" variant="h6">
            No data about proposals
          </Typography>
        )
      }
      {...(withPagination && paginationProps)}
    />
  );
};

const useStyles = makeStyles<Theme, { minHeight: string }>({
  table: {
    minHeight: ({ minHeight }) => minHeight,

    "& .-loading > div": {
      color: "black",
    },

    "& .rt-noData": {
      top: "50%",
      left: "50%",
      color: "black",
      transform: "translate(-50%, -50%)",
    },

    "& .customHeader": {
      margin: 0,
    },

    "& .customHeader > div": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18px",
      fontFamily: "Roboto",
    },

    "& .customHeader > div::after": {
      color: "black",
      fontWeight: 500,
    },
  },
});
