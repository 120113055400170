import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { MenuCards } from "../../../types";
import { MenuOptionCard } from "./MenuOptionCard";
import { MenuOptionDetail } from "./MenuOptionDetail";
import { deskingActions, deskingSelectors } from "../../../model";
import { MenuOptionCardsHeader } from "./MenuOptionCardsHeader";
import { useHorizontalScroll } from "../../../../../hooks/useHorizontalScroll";
import { useModal } from "../../../../../hooks/useModal";
import { ModalsKeys } from "../../../../../global";

export const MenuOptionCards = ({ isCalculating, isSectionOpen }: MenuCards) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.ProductCatalogDialog);

  const scrollRef = useHorizontalScroll();

  const dispatch = useDispatch();

  const menuOption = useSelector(deskingSelectors.selectedMenuOption);
  const selectedMenuOption = useSelector(deskingSelectors.selectedMenuOption);
  const menuOptions = useSelector(deskingSelectors.menuByTerm) as ProposalMenuOption[];

  const onDropProduct = (product: ProposalProduct, menuOptionName: string) => {
    dispatch(
      deskingActions.addMenuProduct({
        menuType: menuOptionName,
        product,
      })
    );
  };

  const onRemoveProduct = (removeIndex: number, currentMenuOption: any) => {
    dispatch(
      deskingActions.removeMenuProduct({
        menuType: currentMenuOption.name,
        removeIndex,
      })
    );

    if (selectedMenuOption.name === currentMenuOption.name && currentMenuOption?.products.length === 1) {
      dispatch(deskingActions.selectMenuOption(currentMenuOption.name));
    }
  };

  const onSelectMenuOption = (optionName: string) => {
    dispatch(deskingActions.selectMenuOption(optionName));
  };

  const onOpenProductDetailsDialog = (proposalProduct: ProposalProduct) => {
    handleOpen({ proposalProduct });
  };

  const moveMenuOption = (dragIndex: number, hoverIndex: number) => {
    const orderedMenuOptions = [...menuOptions];
    orderedMenuOptions.splice(dragIndex, 1);
    orderedMenuOptions.splice(hoverIndex, 0, menuOptions[dragIndex]);
    dispatch(deskingActions.setMenuOptions(orderedMenuOptions));
  };

  return (
    <Box className={classes.cardContainer}>
      <MenuOptionCardsHeader />

      <Box className={classes.cardsContainer} {...({ ref: scrollRef } as any)}>
        {!!menuOptions?.length &&
          menuOptions.map((menuOption, menuOptionIdx) => (
            <MenuOptionCard
              key={menuOption.name}
              menuOption={menuOption}
              menuOptionIdx={menuOptionIdx}
              isCalculating={isCalculating}
              onDropProduct={onDropProduct}
              moveMenuOption={moveMenuOption}
              onRemoveProduct={onRemoveProduct}
              onSelectMenuOption={onSelectMenuOption}
              selectedMenuOption={selectedMenuOption}
              onOpenProductDetailsDialog={onOpenProductDetailsDialog}
            />
          ))}
      </Box>

      <MenuOptionDetail menuOption={menuOption} isSectionOpen={isSectionOpen} />
    </Box>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",
  },
  cardsContainer: {
    gap: "8px",
    display: "flex",
    overflowX: "auto",
    paddingBottom: "6px",
    alignItems: "flex-start",
  },
});
