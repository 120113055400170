import React, { ReactNode, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Divider } from "@material-ui/core";
import { Payment, Proposal } from "@trnsact/trnsact-shared-types";
import { TableView } from "../TableView";
import { createTableColumns } from "./tableConfig";
import { PaymentOptionsDetailsRenderOptions, PaymentOptionsDetailsRenderTypes } from "../../../types";
import { prepareLenderPaymentOptionsForRender } from "../../../lib/prepareProposalDetailsToRender";

interface Props {
  proposal: Proposal;
  loading?: boolean;
  withDetermineStatus?: boolean;
  onOpenDetailsDialog: (details: any) => void;
  onOpenFinanceProgramDialog: (financeProgramId: string) => void;
}

export const PaymentOptionsDetails = ({
  proposal,
  loading,
  onOpenDetailsDialog,
  withDetermineStatus = false,
  onOpenFinanceProgramDialog,
}: Props) => {
  const classes = useStyles();

  const tableData = useMemo(
    () => prepareLenderPaymentOptionsForRender((proposal?.lenderPaymentOptions ?? []) as Payment[]),
    [proposal]
  );

  const columns = createTableColumns({
    onOpenDetailsDialog,
    onOpenFinanceProgramDialog: () => onOpenFinanceProgramDialog((proposal as any).financeProgramId),
  });

  const { variant, declinedTableData, acceptedTableData } = useMemo(() => {
    if (!withDetermineStatus)
      return {
        variant: PaymentOptionsDetailsRenderTypes.WithoutDetermine,
        acceptedTableData: [],
        declinedTableData: [],
      };

    return tableData.reduce<PaymentOptionsDetailsRenderOptions>(
      (acc, option, currentIndex, array) => {
        if (!option) return acc;

        if (option.paymentCriteriaSelected) acc.acceptedTableData.push(option);
        else acc.declinedTableData.push(option);

        if (currentIndex === array.length - 1) {
          acc.variant =
            !acc.declinedTableData.length || !acc.acceptedTableData.length
              ? PaymentOptionsDetailsRenderTypes.WithDetermine
              : PaymentOptionsDetailsRenderTypes.WithoutDetermine;
        }

        return acc;
      },
      {
        variant: PaymentOptionsDetailsRenderTypes.WithoutDetermine,
        acceptedTableData: [],
        declinedTableData: [],
      }
    );
  }, [withDetermineStatus, tableData]);

  const renderByStatus: Record<PaymentOptionsDetailsRenderTypes, ReactNode> = {
    [PaymentOptionsDetailsRenderTypes.WithDetermine]: (
      <TableView<Payment>
        minRows={1}
        loading={loading}
        columns={columns}
        tableData={tableData}
        withPagination={false}
      />
    ),
    [PaymentOptionsDetailsRenderTypes.WithoutDetermine]: (
      <Box className={classes.containerWithStatuses}>
        {!!acceptedTableData.length && (
          <Box className="statusSection">
            <Typography variant="subtitle1" component="span">
              Accepted
            </Typography>

            <TableView<Payment>
              minRows={1}
              loading={loading}
              columns={columns}
              withPagination={false}
              tableData={acceptedTableData}
            />
          </Box>
        )}

        {!!declinedTableData.length && !!acceptedTableData.length && <Divider flexItem style={{ height: "1px" }} />}

        {!!declinedTableData.length && (
          <Box className="statusSection">
            <Typography variant="subtitle1" component="span">
              Declined
            </Typography>

            <TableView<Payment>
              minRows={1}
              loading={loading}
              columns={columns}
              withPagination={false}
              tableData={declinedTableData}
            />
          </Box>
        )}
      </Box>
    ),
  };

  return <>{renderByStatus[variant]}</>;
};

const useStyles = makeStyles({
  containerWithStatuses: {
    gap: "0.75rem",
    display: "flex",
    flexDirection: "column",

    "& .statusSection": {
      gap: "0.5rem",
      display: "flex",
      flexDirection: "column",

      "& > .MuiTypography-root": {
        fontSize: "18px",
        fontWeight: 500,
      },
    },
  },
});
