import { PartnerProfilePartnerLinks } from "@trnsact/trnsact-shared-types";

import { SET_PARTNER_LINKS, CLEAR } from "./actionTypes";

const setPartnerLinks = (payload: PartnerProfilePartnerLinks[]) => ({
  type: SET_PARTNER_LINKS,
  payload,
});

const clearState = () => ({
  type: CLEAR,
});

export const aftermarketPartnerActions = {
  setPartnerLinks,
  clearState,
};
