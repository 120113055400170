import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Link, Typography } from "@material-ui/core";
import { DetailsCellDefaultProps } from "../../../../types";

export const ProductsCell = ({ details }: DetailsCellDefaultProps) => {
  const classes = useStyles();

  const products = useMemo(() => {
    const selectedMenuOption = details?.paymentCriteriaSelected?.menuOption?.products;
    const menuOption = details?.paymentCriteria?.menu?.menuOption?.products ?? [];

    return selectedMenuOption ?? menuOption;
  }, [details]);

  if (!products.length) {
    return (
      <Typography align="center" component="span" variant="body2">
        -
      </Typography>
    );
  }

  return (
    <Box className={classes.container}>
      {products.map((product: any) => (
        <Link
          target="_blank"
          variant="body2"
          key={product.proposalProductId}
          href={`/aftermarket/edit/${product.proposalProductId}`}
        >
          {product.title}
        </Link>
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",

    "& > a.MuiTypography-root": {
      maxWidth: "fit-content",
    },
  },
});
