import React from "react";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FinanceProgramFormValues, MarkupMethods, ProgramTypes, RateTypes, Structures } from "../../../../../types";
import {
  CurrencyInputField,
  FormInputSelect,
  FormRadioGroup,
  FormSwitch,
  InputField,
  InterestInputField,
  MultiSelect,
} from "../../../../../../../components/form";
import {
  markupOptions,
  markupProposalOptions,
  paymentCalculationMethodOptions,
  programTypeOptions,
  rateTypeOptions,
  structureOptions,
  termsOptions,
} from "../../../../../constants";
import { FinanceFieldSelector } from "../../../../components/finance/FinanceFieldSelector";

export const Finance = () => {
  const classes = useStyles();

  const { control, setValue } = useFormContext<FinanceProgramFormValues>();
  const { financeQuote } = useWatch({ control });
  const { remove, append, fields } = useFieldArray({ control, name: "financeQuote.fee" });
  const { fields: specifyRatesFields } = useFieldArray({ control, name: "financeQuote.specifyRates" });

  const handleChangeTerms = (selected: (string | number)[]) => {
    const termsToString = selected.map(String);
    termsToString.sort((a, b) => Number(b) - Number(a));

    setValue("financeQuote.terms", termsToString);
    setValue(
      "financeQuote.specifyRates",
      termsToString.map(term => ({
        term,
        rate: financeQuote?.commonRate ?? "",
      }))
    );
  };

  return (
    <>
      <FinanceFieldSelector />

      <CurrencyInputField
        control={control}
        label="Amount Financed"
        name="financeQuote.amount"
        textFieldProps={{ required: true }}
      />

      <InputField control={control} label="Finance Program Name" name="financeQuote.programName" />

      <Box className="row">
        <Controller
          control={control}
          name="financeQuote.rateType"
          render={({ field }) => (
            <FormRadioGroup
              row
              label="Rate type"
              options={rateTypeOptions}
              {...field}
              onChange={(event, value) => {
                if (value === RateTypes.RateFactor) setValue("financeQuote.specifyRate", true);
                field.onChange(event, value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="financeQuote.specifyRate"
          render={({ field }) => (
            <FormSwitch
              label="Specify rate per term"
              disabled={financeQuote?.rateType === RateTypes.RateFactor}
              {...field}
              checked={field.value}
            />
          )}
        />
      </Box>

      <Box className="row">
        <Controller
          control={control}
          name="financeQuote.terms"
          render={({ field }) => (
            <MultiSelect
              label="Terms in Months"
              options={termsOptions}
              formControlProps={{ required: true, variant: "standard" }}
              {...field}
              onChange={handleChangeTerms}
            />
          )}
        />

        {!financeQuote?.specifyRate && (
          <InterestInputField
            control={control}
            label="Buy Rate (%)"
            name="financeQuote.commonRate"
            textFieldProps={{ required: true, fullWidth: true, size: "small" }}
          />
        )}
      </Box>

      {!!financeQuote?.specifyRate && !!specifyRatesFields?.length && (
        <>
          {specifyRatesFields.map((field, index) => (
            <InterestInputField
              key={field.id}
              control={control}
              name={`financeQuote.specifyRates.${index}.rate`}
              textFieldProps={{ required: true, fullWidth: true }}
              label={`Buy Rate ${financeQuote?.terms?.[index] ?? ""} months (%)`}
            />
          ))}
        </>
      )}

      <Controller
        control={control}
        name="financeQuote.structure"
        render={({ field }) => (
          <FormRadioGroup
            row
            label="Structure"
            defaultValue={financeQuote?.structure}
            options={structureOptions}
            {...field}
          />
        )}
      />

      {financeQuote?.structure === Structures.Markup && (
        <>
          <Controller
            control={control}
            name="financeQuote.markupMethod"
            render={({ field }) => <FormRadioGroup row label="Method" options={markupOptions} {...field} />}
          />

          <InterestInputField
            control={control}
            name="financeQuote.markupRate"
            label={`${financeQuote?.markupMethod === MarkupMethods.Markup ? "Dealer Markup" : "Customer Rate"}  (%)`}
          />
        </>
      )}

      {financeQuote?.structure === Structures.Subsidy && (
        <>
          <Controller
            control={control}
            name="financeQuote.subsidyType"
            render={({ field }) => (
              <FormRadioGroup row label="Subsidy type" options={markupProposalOptions} {...field} />
            )}
          />
        </>
      )}

      <Box className="row">
        <Controller
          control={control}
          name="financeQuote.programType"
          render={({ field }) => (
            <FormInputSelect required variant="standard" label="Program Type" options={programTypeOptions} {...field} />
          )}
        />

        {financeQuote?.programType === ProgramTypes.Lease && (
          <InterestInputField
            control={control}
            label="Residual (%)"
            name="financeQuote.residual"
            textFieldProps={{ required: true, fullWidth: true }}
          />
        )}
      </Box>

      <Box className="row">
        <InputField
          control={control}
          label="Advance Payment (# of)"
          name="financeQuote.advancePayment"
          inputProps={{ type: "number" }}
        />

        <CurrencyInputField
          control={control}
          label="Down Payment"
          name="financeQuote.downPayment"
          textFieldProps={{ required: true, size: "small" }}
        />
      </Box>

      <Controller
        control={control}
        name="financeQuote.paymentCalculationMethod"
        render={({ field }) => (
          <FormInputSelect
            required
            variant="standard"
            label="Payment Calculation Method"
            options={paymentCalculationMethodOptions}
            {...field}
          />
        )}
      />

      <Box className={classes.arrayContainer}>
        {!!fields?.length &&
          fields.map((field, index) => (
            <Box key={field.id}>
              <Box className={classes.array}>
                <Typography component="span" variant="subtitle2">
                  Fee: {index + 1}
                </Typography>

                <Box className={clsx("row", classes.arrayFields)}>
                  <InputField control={control} label="Description" name={`financeQuote.fee.${index}.identifier`} />

                  <CurrencyInputField control={control} label="Fee Amount" name={`financeQuote.fee.${index}.fee`} />
                </Box>

                <IconButton onClick={() => remove(index)}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Controller
                control={control}
                name={`financeQuote.fee.${index}.financed`}
                render={({ field }) => (
                  <FormSwitch label="Financed?" defaultChecked={field.value ? field.value : false} {...field} />
                )}
              />
            </Box>
          ))}

        <Box>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => append({ financed: true, fee: 0, identifier: "" })}
          >
            Add Fee
          </Button>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  arrayContainer: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  array: {
    gap: "12px",
    display: "flex",
  },
  arrayFields: {
    flex: 1,
  },
});
