import { Typography } from "@material-ui/core";
import { SimpleTextCell } from "components/Table/Cells/SimpleTextCell";
import { AftermarketPartnerDealer, CellArgs } from "modules/aftermarketPartner/types";
import React from "react";
import { Column } from "react-table";
/* import { DateCell } from "./cells/DateCell";
import { StageCell } from "./cells/StageCell";
import { ActionCell } from "./cells/ActionCell";
import { StatusCell } from "./cells/StatusCell";
import { HubProposal } from "../../../api/types";
import { CustomerCell } from "./cells/CustomerCell";
import { formatCurrency } from "../../../../../utils";
import { SimpleTextCell } from "./cells/SimpleTextCell";
import { getPersonaName } from "../../../lib/getPersonaName";
import { MultiSelectFilter } from "../../components/ColumnMultiSelectFilter";
import { hubSearchFilterByColumn } from "../../../lib/hubSearchFilters";
import { CellArgs } from "../../../types"; */

interface Options {
  tableData: AftermarketPartnerDealer[];
}

export function createTableColumns({ tableData }: Options): Column<AftermarketPartnerDealer>[] {
  return [
    {
      sortable: true,
      Header: "Dealer Name",
      accessor: "accountName",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.accountName} />,
    },
    {
      sortable: true,
      Header: "Status",
      accessor: "status",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.status} />,
    },
    {
      sortable: true,
      Header: "Active Menus",
      accessor: "activeMenus",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.activeMenus} />,
    },
    {
      sortable: true,
      Header: "Recent Quotes",
      accessor: "recentQuotes",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.recentQuotes} />,
    },
    {
      sortable: true,
      Header: "Recent Contracts",
      accessor: "recentContracts",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.recentContracts} />,
    },
    {
      sortable: true,
      Header: "Products Sold",
      accessor: "productsSold",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.productsSold} />,
    },
    {
      sortable: true,
      Header: "Total Volume",
      accessor: "totalVolume",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.totalVolume} />,
    },
  ].map(header => ({
    ...header,
    resizable: false,
    headerClassName: "customHeader",
  }));
}
