import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deskingActions, deskingSelectors } from "../../../model";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputSelect } from "../../../../../components/form";
import { convertProgramConfigToFormValues } from "../../../lib";
import { FinanceProgramFormValues } from "../../../types";

export const FinanceFieldSelector = () => {
  const { control, setValue } = useFormContext<FinanceProgramFormValues>();

  const dispatch = useDispatch();

  const financePrograms = useSelector(deskingSelectors.financePrograms);
  const financeProgramsSelectors = useSelector(deskingSelectors.financeProgramsSelectors);

  const handleSelectProgram = (programId: string) => {
    setValue("financeQuote.program", programId);
    const programConfig = financePrograms?.[programId]?.paymentOptionsConfiguration ?? null;

    if (!programConfig) return;

    const values = convertProgramConfigToFormValues(programConfig);

    dispatch(deskingActions.resetMenuBuilder());

    if (values.terms?.length) {
      dispatch(
        deskingActions.setMenuTerm({
          index: values.terms.length - 1,
          term: values.terms[values.terms.length - 1],
        })
      );
    }

    Object.entries(values).forEach(([key, value]) => setValue(`financeQuote.${key}` as any, value));
  };
  return (
    <Controller
      control={control}
      name="financeQuote.program"
      render={({ field }) => (
        <FormInputSelect
          variant="standard"
          label="Select Program"
          options={financeProgramsSelectors ?? []}
          disabled={!financeProgramsSelectors?.length}
          {...field}
          onChange={handleSelectProgram}
        />
      )}
    />
  );
};
