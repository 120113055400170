import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { Box, FormControl, InputLabel, makeStyles, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

interface Props {
  page: number;
  pageSize: number;
  resolvedData: any[];
  onPageChange: (nextPage: number) => void;
  onPageSizeChange: (nextPage: number) => void;
}

export const CustomPagination = ({ pageSize, resolvedData, page, onPageChange, onPageSizeChange }: Props) => {
  const classes = useStyles();

  const totalPages = Math.max(Math.ceil(resolvedData.length / pageSize), 0);

  const handlePageChange = (_: any, value: any) => {
    onPageChange(value - 1);
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onPageSizeChange(event.target.value as number);
  };

  const options = [5, 10, 25]
    .filter(value => value <= resolvedData.length)
    .map(option => (
      <MenuItem key={option} value={option}>
        {`${option} rows`}
      </MenuItem>
    ));

  return (
    <Box className={classes.pagination}>
      <Pagination
        size="large"
        variant="text"
        color="primary"
        shape="rounded"
        showLastButton
        page={page + 1}
        showFirstButton
        count={totalPages}
        onChange={handlePageChange}
      />

      <FormControl variant="standard" size="small" style={{ minWidth: "14rem", maxWidth: "14rem" }}>
        <InputLabel>Show</InputLabel>

        <Select value={pageSize} disabled={!options.length} onChange={handleItemsPerPageChange} label="Show">
          {options}
        </Select>
      </FormControl>
    </Box>
  );
};

const useStyles = makeStyles({
  pagination: {
    width: "100%",
    display: "flex",
    paddingTop: "24px",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
