import { VendorOpportunity } from "@trnsact/trnsact-shared-types";
import { equipmentMileageFormatter } from "./equipmentMileageFormatter";
import {
  FinanceProgramFormValues,
  FinanceQuoteMode,
  MarkupMethods,
  MarkupType,
  PaymentCalculationMethods,
  ProgramTypes,
  RateTypes,
  SendVia,
  Structures,
} from "../types";

export const sendViaInitialValues = {
  type: SendVia.Email,
};

export const displaySettingsInitialValues = {
  productPrice: true,
  productDetails: true,
};

export function convertVOToInitialFormValue(
  voData: VendorOpportunity,
  creditAppData: any,
  equipments: any
): Pick<FinanceProgramFormValues, "saleDetails" | "customer" | "equipment"> {
  const customerData = creditAppData.creditApplicationOwner?.[0];
  const equipmentData = equipments?.equipments?.[0];

  return {
    customer: {
      title: customerData?.title ?? "",
      email: customerData?.email ?? "",
      phone:
        (customerData?.phone || customerData?.mobilePhone || customerData?.homePhone || customerData?.workPhone) ?? "",
      lastName: customerData?.lastName ?? "",
      name: creditAppData?.businessName ?? "",
      firstName: customerData?.firstName ?? "",
      state: customerData?.state ?? "",
      selectedCustomer: customerData?.ownerPgId,
    },
    equipment: {
      selectedEquipment: equipmentData?.equipmentId ?? "",
      make: equipmentData?.make ?? "",
      year: equipmentData?.year ?? "",
      model: equipmentData?.model ?? "",
      mileage: equipmentMileageFormatter(equipmentData?.mileage, "toView"),
      stockId: equipmentData?.stockId ?? "",
      quantity: equipmentData?.quantity ?? "",
      newUsed: equipmentData?.newUsed ?? "",
      totalRetailPrice: equipmentData?.retailPrice ?? "",
      serialNumberVin: equipmentData?.serialNumberVin ?? "",
      wholesaleCost: equipmentData?.totalWholesaleCost ?? "",
      description: equipmentData?.additionalDescription ?? "",
      totalWholesaleCost: equipmentData?.totalWholesaleCost ?? "",
    },
    saleDetails: {
      fees: voData.docFeeAmount ?? 0,
      downPayment: voData.downPayment ?? 0,
      invoiceTotal: voData.invoiceTotal ?? 0,
      financeAmount: voData.invoiceTotal ?? 0,
      salesTax: voData.salesTaxOnInvoice ?? 0,
      equipmentTotal: voData.equipmentCost ? +voData.equipmentCost : 0,
    },
  };
}

export function getDefaultValues(
  voData: VendorOpportunity,
  creditAppData: any,
  equipmentData: any
): FinanceProgramFormValues {
  const initialPartOfValue = convertVOToInitialFormValue(voData, creditAppData, equipmentData);

  return {
    ...initialPartOfValue,
    financeQuote: {
      fee: [],
      menu: "",
      commonRate: "",
      terms: [],
      amount: voData.invoiceTotal?.toString() ?? "",
      program: "",
      residual: "",
      markupRate: "",
      downPayment: "",
      programName: "",
      specifyRates: [],
      dealerMarkup: "",
      advancePayment: "",
      specifyRate: false,
      specifyRatePerTerm: "",
      structure: Structures.None,
      subsidyType: MarkupType.Flat,
      mode: FinanceQuoteMode.Finance,
      programType: ProgramTypes.Loan,
      rateType: RateTypes.InterestRate,
      markupMethod: MarkupMethods.Markup,
      paymentCalculationMethod: PaymentCalculationMethods.Advance,
    },
    sendVia: sendViaInitialValues,
    displaySettings: displaySettingsInitialValues,
    financeProgramDetails: {},
  };
}
