import React from "react";

import ReactQuill from "react-quill";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  header?: string;
  catalogDetails: ReactQuill.Value;
}

export const ProductCatalogDetails = ({ header, catalogDetails }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      {header && <h4 className={classes.header}>{header}</h4>}

      <ReactQuill className={classes.catalogDetails} value={catalogDetails} readOnly={true} theme={"bubble"} />
    </Box>
  );
};

const useStyles = makeStyles({
  header: {
    fontWeight: 500,
    margin: "5px 0",
  },
  catalogDetails: {
    "& .ql-editor": {
      padding: 0,
    },
  },
});
