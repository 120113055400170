import React, { useMemo } from "react";
import { Link, Typography } from "@material-ui/core";
import { DetailsCellDefaultProps } from "../../../../types";

export const MenuCell = ({ details }: DetailsCellDefaultProps) => {
  const { packageName, menuId } = useMemo(() => {
    const selectedMenuOption = details?.paymentCriteriaSelected?.menuOption?.name;
    const menuOption = details?.paymentCriteria?.menu?.menuOption?.name;

    return {
      packageName: selectedMenuOption ?? menuOption,
      menuId: details?.paymentCriteriaSelected?.proposalMenuId ?? details?.paymentCriteria?.menu?.proposalMenuId,
    };
  }, [details]);

  if (!menuId) {
    return (
      <Typography variant="body2">
        {details.paymentCriteria?.menu?.name} | {packageName}
      </Typography>
    );
  }

  return (
    <Link variant="body2" target="_blank" href={`/aftermarket/menu/edit/${menuId}`}>
      {details.paymentCriteria?.menu?.name} | {packageName}
    </Link>
  );
};
