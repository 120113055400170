import gql from "graphql-tag";

export const GET_PARTNER_LINKS = gql`
  query GetPartnerLinks($dynamicsId: ID!) {
    getPartnerLinks(dynamicsId: $dynamicsId) {
      accountName
      primaryContactId
      primaryContactFullName
      primaryContactEmail
      dynamicsPartnerAccountId
      dynamicsPartnerLinkId
    }
  }
`;
