import React, { Fragment, ReactNode } from "react";
import { useSelector } from "react-redux";
import WorkflowConstructor from "./index";
import { ModalsKeys } from "../../global";
import { FinanceProgramModal, PreviewFinanceProgramModal } from "../../modules/financeProgram";
import { portalConfigurationsSelectors } from "../../redux/portalConfigurationReducer";
import { ModalsContainer } from "../../components/shared/Modals";

export const PrescreenModuleContainer = () => {
  const account = useSelector((state: any) => state.account);
  const vp = useSelector((state: any) => state.vp);

  // Waterfall active (currently) determines if the waterfall that is saved to the DB is run or not on credit app receipt.
  // The only way this is false is if "Prescreen Management -> Module Type" is set to "Off" in the PCW
  const waterfallActive = useSelector(portalConfigurationsSelectors.isPrescreenModuleActive);
  const type = useSelector(portalConfigurationsSelectors.prescreenModuleType);
  const moduleName = useSelector(portalConfigurationsSelectors.prescreenModuleName);

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.FinanceForm]: <FinanceProgramModal />,
    [ModalsKeys.PreviewFinanceForm]: <PreviewFinanceProgramModal />,
  };

  return (
    <>
      <WorkflowConstructor vp={vp} type={type} account={account} moduleName={moduleName} waterfallActive={true} />

      <ModalsContainer modalsWithKeys={modalsWithKeys} />
    </>
  );
};
