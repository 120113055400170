import React, { CSSProperties, ReactNode } from "react";
import { Add, Remove } from "@material-ui/icons";
import { Box, IconButton, Typography } from "@material-ui/core";
import { FormSectionProps } from "../../types";

interface Props extends Pick<FormSectionProps, "sectionName" | "onUpdateCollapse"> {
  name: string;
  sx?: CSSProperties;
  isSectionOpen: boolean;
  extraAction?: ReactNode;
}

export const FormSectionHeader = ({
  name,
  sectionName,
  extraAction,
  isSectionOpen,
  onUpdateCollapse,
  sx = {},
}: Props) => {
  return (
    <Box style={sx} className="sectionHeader">
      <Box className="collapse">
        {!!onUpdateCollapse && (
          <IconButton size="small" onClick={() => onUpdateCollapse(sectionName, !isSectionOpen)}>
            {isSectionOpen ? <Remove /> : <Add />}
          </IconButton>
        )}

        {name && (
          <Typography component="span" variant="subtitle2">
            {name}
          </Typography>
        )}
      </Box>

      {extraAction && extraAction}
    </Box>
  );
};
