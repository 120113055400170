import React, { useState } from "react";
import { blue } from "@material-ui/core/colors";
import { Box, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ProposalMenuOption, ProposalProduct } from "@trnsact/trnsact-shared-types";
import { MenuOptionProductDescriptions } from "./MenuOptionProductDescriptions";
import { Add, Remove } from "@material-ui/icons";

interface Props {
  menuOption: ProposalMenuOption;
}

export const MenuOptionProductsDescriptions = ({ menuOption }: Props) => {
  const classes = useStyles();

  const [isSectionOpen, setIsSectionOpen] = useState<boolean>(false);

  return (
    <Box>
      <Box className={classes.header}>
        <IconButton size="small" onClick={() => setIsSectionOpen(prev => !prev)}>
          {isSectionOpen ? <Remove /> : <Add />}
        </IconButton>

        <Typography component="span" variant="subtitle2">
          Product Description(s)
        </Typography>
      </Box>

      {isSectionOpen &&
        (menuOption.products as ProposalProduct[]).map(product => (
          <MenuOptionProductDescriptions key={product.proposalProductId} menuOption={menuOption} product={product} />
        ))}
    </Box>
  );
};

const useStyles = makeStyles({
  header: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: blue["50"],
  },
});
