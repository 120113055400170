import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { FormInput, FormInputSelect } from "components/form";
import { useSimpleAutoFill } from "../../../../../hooks";
import { SelectOption } from "../../../../../../../global";
import { SimpleProductsDynamicFieldsProps } from "../../../../../types";
import Delta from "quill-delta";

export const StringField = ({
  proposalProduct,
  fact,
  facts,
  setFacts,
  disabled,
  productConfigurationRulesEngine,
}: SimpleProductsDynamicFieldsProps) => {
  const updateCoverageProductCatalogDetails = (value: string) => {
    if (fact.factKey === "COVERAGE_TERM") {
      try {
        let newCoverageDetails: any[] = [];
        if (proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails?.coverageDetails) {
          newCoverageDetails = proposalProduct.aftermarketProduct.aftermarketProductCatalogDetails.coverageDetails;
          newCoverageDetails = newCoverageDetails.filter((item: any) => !item.insert.includes("Coverage Term:"));
        }
        newCoverageDetails = newCoverageDetails.reverse();
        const newCoverageElement = new Delta().insert(`Coverage Term: ${value}\n`);
        newCoverageDetails = newCoverageDetails.concat(newCoverageElement.ops);
        newCoverageDetails = newCoverageDetails.reverse();
        proposalProduct.aftermarketProduct!.aftermarketProductCatalogDetails!.coverageDetails = newCoverageDetails;
      } catch (e) {
        console.log(`Error Setting COVERAGE_TERM through simple product: ${e}`);
      }
    }
  };

  const onChange = (value: string) => {
    setFacts((prev: any) => {
      console.log(`proposalProduct: ${proposalProduct}`);
      updateCoverageProductCatalogDetails(value);
      productConfigurationRulesEngine.current.addFact(fact.factKey, value);

      return { ...prev, [fact.factKey]: value };
    });
  };

  useEffect(() => {
    updateCoverageProductCatalogDetails(facts[fact.factKey]);
  }, []);

  const selectValue = useMemo(() => {
    if (facts[fact.factKey]) return facts[fact.factKey];

    const isSingleOption = fact.options && fact.options.length === 1;

    if (isSingleOption) onChange(fact?.options?.[0] ?? "");

    return isSingleOption ? fact?.options?.[0] : "";
  }, [onChange]); // fact, facts, onChange

  const options: SelectOption[] = [
    { label: "None of these / I don't Know", value: "None of These" },
    ..._.orderBy(fact.options, "asc").map(option => ({ label: option, value: option })),
  ];

  useSimpleAutoFill(onChange, fact);

  return (
    <>
      {fact.options ? (
        <FormInputSelect
          options={options}
          variant="standard"
          value={selectValue}
          onChange={onChange}
          disabled={disabled || !options.length}
          label={fact.factKey.replace(/_/g, " ")}
        />
      ) : (
        <FormInput
          disabled={disabled}
          onChange={onChange}
          value={facts[fact.factKey]}
          label={fact.factKey.replace(/_/g, " ")}
        />
      )}
    </>
  );
};
