import React, { useEffect, useState } from "react";
import clsx from "clsx";
import ReactTable from "react-table";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AftermarketPartnerDealer } from "modules/aftermarketPartner/types";
import { CustomPagination } from "components/Table/Pagination";
import { usePagination } from "hooks/usePagination";
import { createTableColumns } from "./tableConfig";

interface Props {
  loading?: boolean;
  tableData: AftermarketPartnerDealer[];
}

export const DealerManagementTable = ({ tableData, loading }: Props) => {
  const classes = useStyles();

  const columns = createTableColumns({ tableData });

  const paginationProps = usePagination(tableData.length);

  return (
    <ReactTable<AftermarketPartnerDealer>
      loading={loading}
      columns={columns}
      showPaginationBottom
      data={loading ? [] : tableData}
      PaginationComponent={CustomPagination}
      className={clsx("-highlight", classes.table)}
      loadingText={
        <Typography component="span" variant="h6">
          Dealers loading...
        </Typography>
      }
      noDataText={
        !loading && (
          <Typography component="span" variant="h6">
            No connected Dealers yet
          </Typography>
        )
      }
      {...paginationProps}
    />
  );
};

const useStyles = makeStyles({
  table: {
    minHeight: "33.5rem",

    "& .-loading > div": {
      color: "black",
    },

    "& .rt-noData": {
      top: "50%",
      left: "50%",
      color: "black",
      transform: "translate(-50%, -50%)",
    },

    "& .customHeader": {
      margin: 0,
    },

    "& .customHeader > div": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18px",
      fontFamily: "Roboto",
    },

    "& .customHeader > div::after": {
      color: "black",
      fontWeight: 500,
    },
  },
});
