import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import gql from "graphql-tag";
import { publicRoutes } from "routes.js";
/* import Dashboard from "./layouts/Dashboard.js"; */
import { DashboardV2 } from "./layouts/DashboardV2";
import AuthLayout from "./layouts/Auth.js";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import Amplify, { Auth } from "aws-amplify";
import { AWS_CONFIG, environmentName } from "./config";
import { connect, useDispatch, useSelector } from "react-redux";
import { setRefetch, setValue } from "./redux/actions";
import TermsAndConditionsPage from "./pages/Auth/TermsAndConditionsPage";
import mixpanel from "./mixpanel";
import _ from "lodash";
import { CHAT_V2_SET_RECIPIENTS_LIST } from "redux/types";
import { elementReady, vendorContactRoleHumanReadable } from "utils";
import { SET_ACCOUNT, SET_LENDER_PROFILE, SET_RELATED_USERS, SET_USER_PROFILE } from "./copiedLP/src/redux/types";
import { CommonDataContext } from "./contexts/CommonDataContext";
import moment from "moment";
import RedFlagsiFrame from "pages/RedFlagsiFrame.js";
import Backdrop from "@material-ui/core/Backdrop";
import UserSessionControl from "components/UserSessionControl";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/img/trnsact-logo-dark.png";
import Theme from "./theme";
import { ViewTypeContextProvider } from "./contexts/contentViewType";
import { CommonModals } from "./components/shared/Modals";
import { QUERY_PORTAL_CONFIGURATION } from "./queries/common";
import { portalConfigurationsActions } from "./redux/portalConfigurationReducer";
import { initialPortalConfigTypes } from "./constants";
import { getDefaultPortalConfigurationByType } from "./services/pcwService";
import { NotificationProvider } from "./modules/notification";
import { Constructor } from "pages/DynamicOCA/Constructor";

Auth.configure({ AWS_CONFIG });
Amplify.configure(AWS_CONFIG);

const LOGGED_IN_FLAG_NAME = "LoggedIn";

const GET_ACCOUNT = gql`
  query InitialQuery($userProfileId: ID) {
    partner(userProfileId: $userProfileId) {
      hasApi
      name
      partnerLogo
      isAftermarketProd
      partnerDynamicsProfileId
    }
    account(userProfileId: $userProfileId) {
      id
      name
      vendorProfileId
      dynamicsAccountId
      primaryContactId
      primaryContactName
      primaryContactPhone
      primaryContactEmail
      accountManagerDynamicsId
      address
      phone
      accountType
      domain
      isLenderIntegrationSettingsAdmin
      contacts {
        id
        email
        firstName
        lastName
        fullName
        title
        accountId
        dynamicsContactId
        vendorContactRole
      }
    }

    userProfile {
      id
      firstName
      lastName
      fullName
      mugshot
      phoneNumber
      vendorContactRole
      email
      dynamicsContactId
      mobilePhoneNumber
      portalType
      title
      status
      lastLogin
      locationId
      adminRole
      isDemoMode
      lenderContactRole
    }
    vendorProfile {
      id
      name
      dynamicsId
      logo
      showPrequal
      showPortalCreditApp
      showDocMgt
      showLenderDocsAccessMgmt
      showCreditApp
      showPsOptions
      showMarginTool
      showLenderSubmission
      industryGroupType
      role
      showChat
      manualVendorOpportunityCreationAccess
      showTasks
      showRouteOneApp
      showPqt
      creditSubmissionDisplay
      altLanguageOca
      showProposal
      showAftermarketOptions
      docusignDocMgmt
      defaultOcaContact
      softPullInDealerPortal
      ofacRedFlagComplianceAccess
      multiLocationManagement
      dlRequired
      dlUploadRequired
      ocaForm
      ocaDocUpload
      amountRequested
      ownershipRequirement
      industryGroupType
      fastTrackSetting
      markupDealer
      styles
      ofacRedFlagComplianceAccess
      territoryManagement
      lenderMatrixAccess
      hasLenderOcaAccess
      hasLenderFinanceProposalsAccess
      proposalMenuAccess
      allowSyndication
      logoColors
      daysToCreditAppRecertification
      requireCreditAppRecertification
      creditPullSettings {
        vendorProfileCreditPullSettingsId
        vendorProfileId
        autoPull
        creditBureauType
      }
      settingBusinessCreditReportPullInDealerPortal
      ocaDefaultContactInfo {
        id
        email
        dynamicsContactId
        firstName
        lastName
        fullName
        title
        phoneNumber
        mobilePhoneNumber
      }
      creditProducts {
        productId
        productHeader
        productName
        lenderProgramId
        inputRequirements
      }
      lenderProfiles {
        id
        hasActiveUser
        lenderName
        lenderLogo
        showCustomApp
        draftApplicationsEnabled
        pdfTemplate
        submissionMethod
        lenderProfileId
        lenderAccountId
        lenderContactId
        lenderChannelCode
        lenderTypeCode
        dealerRelationship
        submissionReviewShowMappingModal # ON_DISPLAYS_MAPPING_MODAL | OFF_NO_REVIEW_SHOWN
        insuranceRequirements
        lenderUserProfile {
          id
          email
          dynamicsContactId
          firstName
          lastName
          fullName
          phoneNumber
          mobilePhoneNumber
          mugshot
          title
          accountId
        }
        lenderAccount {
          id
          dynamicsAccountId
          vendorProfileId
          primaryContactId
          name
          address
          phone
          primaryContactName
          primaryContactPhone
          primaryContactEmail
        }
      }
    }
    accountRep {
      fullName
      email
      phoneNumber
      mugshot
    }
  }
`;
const Q_DEALER_RELATED_USERS = gql`
  query RelatedUsers($accountId: ID) {
    relatedUsers(accountId: $accountId) {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mobilePhoneNumber
      mugshot
      availability
      vendorContactRole
      title
      accountId
    }
  }
`;

const Q_LENDER_RELATED_USERS = gql`
  query LendersRelatedUsers($dynamicsId: ID!) {
    lendersRelatedUsers(dynamicsId: $dynamicsId) {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      availability
      vendorContactRole
      mobilePhoneNumber
      title
      accountId
      phoneNumber
      mugshot
      account {
        id
        name
      }
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation($entityId: String!, $fieldsObj: AdminUpdateUserProfileInput!) {
    adminUpdateUserProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

const GET_DASHBOARDS = gql`
  query {
    dashboards {
      dashboardId
      dashboardName
      dashboardDescription
      dashboardUrl
      superAdminOnly
    }
  }
`;

const Q_LENDER_PROFILE = gql`
  query {
    lenderProfile {
      id # Dynamics ID
      lenderProfileId # Postgres ID
      name
      dynamicsContactSubmission
      logo
      showCustomApp
      pdfTemplate
      fdrContact {
        dynamicsContactId
        fullName
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  progressLoader: {
    margin: 32,
    color: theme.palette.grey[500],
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",

    "& img": {
      maxWidth: 200,
    },
  },
}));

function App({ setValue, reduxRefetch, setRefetch }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const chatOpened = useSelector(state => state.chatV2.open);
  const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = useState(undefined);
  const [dashboards, setDashboards] = useState();
  const [showPrequal, setShowPrequal] = useState(false);

  const [isDealerUser, setIsDealerUser] = useState(false);
  const [isLenderUser, setIsLenderUser] = useState(false);
  const [isCommonPartner, setIsCommonPartner] = useState(false);
  const [isAftermarketPartner, setIsAftermarketPartner] = useState(false);
  const [isNewVoLayout, setIsNewVoLayout] = useState(environmentName === "dev");

  const [siteLoading, setSiteLoading] = useState(true);

  const [getRelatedUsers, { data: dealerRelatedUsersData, refetch: relatedUsersRefetch }] = useLazyQuery(
    Q_DEALER_RELATED_USERS,
    {
      context: { authRequired: true },
    }
  );

  const [getLenderRelatedUsers, { data: lenderRelatedUsersData, refetch }] = useLazyQuery(Q_LENDER_RELATED_USERS, {
    context: { authRequired: true },
  });

  const [
    getAccountData,
    { data: accountData, refetch: accountDataRefetch, loading: loadingAccountData },
  ] = useLazyQuery(GET_ACCOUNT, {
    fetchPolicy: "cache-and-network",
    context: { authRequired: true },
    onCompleted: () => {
      setSiteLoading(false);
    },
  });

  useQuery(QUERY_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    skip: !accountData?.vendorProfile?.id,
    variables: {
      vendorProfileId: accountData?.vendorProfile?.id,
      types: initialPortalConfigTypes,
    },
    onCompleted(data) {
      const portalConfigurationsWithDefault = initialPortalConfigTypes.map(configType => {
        const savedConfig = data?.portalConfigurations?.find(({ type }) => type === configType);

        if (savedConfig) return savedConfig;

        return getDefaultPortalConfigurationByType(configType, accountData?.vendorProfile?.id);
      });

      dispatch(portalConfigurationsActions.setConfigData(portalConfigurationsWithDefault));
    },
  });

  const [getLenderProfile, { data: lenderProfile }] = useLazyQuery(Q_LENDER_PROFILE, {
    context: { authRequired: true },
  });

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);

  const [getDashboards, { data: dashboardsData }] = useLazyQuery(GET_DASHBOARDS, {
    context: { authRequired: true },
  });

  useEffect(() => {
    if (window.location.hash) {
      console.log(`Scrolling to ${window.location.hash}`);
      elementReady(window.location.hash).then(target => {
        target.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    }
  }, [window.location.hash]);

  useEffect(() => {
    if (loadingAccountData) {
      console.log("%cLoading Account Data", "color:green");
      setSiteLoading(true);
    }
  }, [loadingAccountData]);

  useEffect(() => {
    // async loading of the current user
    Auth.currentAuthenticatedUser()
      .then(user => {
        setCurrentAuthenticatedUser(user);
        //UX improvement: setting the backgrop while we load the account data
        setSiteLoading(true);
        getAccountData();
      })
      .catch(() => {
        setCurrentAuthenticatedUser(null);
        mixpanel.reset();
      });
  }, []);

  useEffect(() => {
    if (!dashboardsData) {
      return;
    }
    setDashboards(dashboardsData.dashboards);
  }, [dashboardsData]);

  useEffect(() => {
    if (_.isEmpty(accountData)) {
      return;
    }

    // did we just log in?
    if (sessionStorage.getItem(LOGGED_IN_FLAG_NAME)) {
      // clearing the flag that we just logged in
      sessionStorage.removeItem(LOGGED_IN_FLAG_NAME);
      // tracking the "Login" event
      mixpanel.track("Login");
    }

    if (
      !accountData.userProfile.lastLogin ||
      accountData.userProfile.lastLogin.split("T")[0] !== moment().format("YYYY-MM-DD")
    ) {
      const dynamicsContactId = accountData.userProfile.dynamicsContactId;
      updateUserProfile({
        variables: {
          entityId: dynamicsContactId,
          fieldsObj: {
            status: "active",
            lastLogin: moment().format("YYYY-MM-DD"),
          },
        },
      });
    }

    const isPartner = accountData.account.accountType === "Partner";
    const isAftermarketPartner = isPartner && accountData.partner.isAftermarketProd;
    setIsDealerUser(accountData.userProfile.portalType === "1");
    setIsLenderUser(accountData.userProfile.portalType === "2");
    setIsCommonPartner(isPartner && !isAftermarketPartner);
    setIsAftermarketPartner(isAftermarketPartner);

    setValue("account", { ...accountData.account, partner: _.get(accountData, "partner") });
    setValue("accountRep", accountData.accountRep);
    setValue("userProfile", accountData.userProfile);
    setValue("vp", accountData.vendorProfile);
    setValue("lp", _.get(accountData, "vendorProfile.lenderProfiles", null));
  }, [accountData]);

  useEffect(() => {
    if (!isLenderUser) {
      return;
    }
    if (_.isEmpty(accountData)) {
      return;
    }
    getLenderProfile();

    dispatch({ type: SET_ACCOUNT, payload: { ...accountData.account, partner: _.get(accountData, "partner") } });
    dispatch({ type: SET_USER_PROFILE, payload: accountData.userProfile });

    getLenderRelatedUsers({
      variables: { dynamicsId: accountData.userProfile.dynamicsContactId },
    });
    mixpanel.register({
      accountId: _.get(accountData, "account.id"),
      accountName: _.get(accountData, "account.name"),
      dynamicsAccountId: _.get(accountData, "account.dynamicsAccountId"),
      environment: environmentName,
      portal: "lender",
    });
  }, [isLenderUser, accountData]);

  useEffect(() => {
    if (!isDealerUser) {
      return;
    }
    if (_.isEmpty(accountData)) {
      return;
    }
    console.log("Account Data", accountData);

    const mouseflowTrackingData = accountData => {
      window.waitForGlobal("_mfq", function() {
        //Mouseflow Account Name
        if (_.get(accountData, "account.name", false)) {
          window._mfq.push(["setVariable", "account", _.get(accountData, "account.name")]);
        }

        //Mouseflow VP Settings
        if (_.get(accountData, "vendorProfile.id", false)) {
          window._mfq.push(["setVariable", "vendorProfileId", _.get(accountData, "vendorProfile.id")]);
          window._mfq.push(["setVariable", "vpSettingShowPrequal", _.get(accountData, "vendorProfile.showPrequal")]);
          window._mfq.push([
            "setVariable",
            "vpSettingShowPortalCreditApp",
            _.get(accountData, "vendorProfile.showPortalCreditApp"),
          ]);
          window._mfq.push(["setVariable", "vpSettingShowDocMgt", _.get(accountData, "vendorProfile.showDocMgt")]);
          window._mfq.push([
            "setVariable",
            "vpSettingShowLenderDocsAccessMgmt",
            _.get(accountData, "vendorProfile.showLenderDocsAccessMgmt"),
          ]);
          window._mfq.push([
            "setVariable",
            "vpSettingShowCreditApp",
            _.get(accountData, "vendorProfile.showCreditApp"),
          ]);
          window._mfq.push([
            "setVariable",
            "vpSettingShowPsOptions",
            _.get(accountData, "vendorProfile.showPsOptions"),
          ]);
          window._mfq.push([
            "setVariable",
            "vpSettingShowMarginTool",
            _.get(accountData, "vendorProfile.showMarginTool"),
          ]);
          window._mfq.push(["setVariable", "vpSettingShowChat", _.get(accountData, "vendorProfile.showChat")]);
          window._mfq.push([
            "setVariable",
            "vpSettingmanualVendorOpportunityCreationAccess",
            _.get(accountData, "vendorProfile.manualVendorOpportunityCreationAccess"),
          ]);
          window._mfq.push(["setVariable", "vpSettingShowTasks", _.get(accountData, "vendorProfile.showTasks")]);
          window._mfq.push([
            "setVariable",
            "vpSettingShowRouteOneApp",
            _.get(accountData, "vendorProfile.showRouteOneApp"),
          ]);
          window._mfq.push(["setVariable", "vpSettingShowProposal", _.get(accountData, "vendorProfile.showProposal")]);
          window._mfq.push([
            "setVariable",
            "vpSettingShowAftermarketOptions",
            _.get(accountData, "vendorProfile.showAftermarketOptions"),
          ]);
          window._mfq.push(["setVariable", "vpSettingShowPqt", _.get(accountData, "vendorProfile.showPqt")]);
          window._mfq.push([
            "setVariable",
            "vpSettingCreditSubmissionDisplay",
            _.get(accountData, "vendorProfile.creditSubmissionDisplay"),
          ]);
          window._mfq.push([
            "setVariable",
            "vpSettingShowLenderSubmission",
            _.get(accountData, "vendorProfile.showLenderSubmission"),
          ]);
        }
        //Mouseflow User Profile Data
        if (_.get(accountData, "userProfile.fullName", false)) {
          window._mfq.push(["setVariable", "userFullname", _.get(accountData, "userProfile.fullName")]);
          window._mfq.push(["setVariable", "userEmail", _.get(accountData, "userProfile.email")]);
          window._mfq.push(["setVariable", "userPortalType", _.get(accountData, "userProfile.portalType")]);
          window._mfq.push(["setVariable", "userDynamicsId", _.get(accountData, "userProfile.dynamicsContactId")]);
          window._mfq.push(["setVariable", "userVendorRole", _.get(accountData, "userProfile.vendorContactRole")]);
          window._mfq.push(["setVariable", "userMobilePhone", _.get(accountData, "userProfile.mobilePhoneNumber", "")]);
        }
      });
    };

    getRelatedUsers({
      variables: { accountId: _.get(accountData, "account.id", "") },
    });
    getDashboards();
    setShowPrequal(!!accountData.vendorProfile?.showPrequal);
    mouseflowTrackingData(accountData);
    mixpanel.register({
      accountId: _.get(accountData, "account.id"),
      accountName: _.get(accountData, "account.name"),
      dynamicsAccountId: _.get(accountData, "account.dynamicsAccountId"),
      environment: environmentName,
      portal: "dealer",
    });
  }, [isDealerUser, accountData]);

  useEffect(() => {
    if (_.isEmpty(lenderProfile)) {
      return;
    }

    const mouseflowTrackingData = () => {
      try {
        window.waitForGlobal("_mfq", function() {
          //Mouseflow Account Name
          if (_.get(accountData, "account.name", false)) {
            window._mfq.push(["setVariable", "account", _.get(accountData, "account.name")]);
          }

          //Mouseflow User Profile Data
          if (_.get(accountData, "userProfile.fullName", false)) {
            window._mfq.push(["setVariable", "userFullname", _.get(accountData, "userProfile.fullName")]);
            window._mfq.push(["setVariable", "userEmail", _.get(accountData, "userProfile.email")]);
            window._mfq.push(["setVariable", "userPortalType", _.get(accountData, "userProfile.portalType")]);
            window._mfq.push(["setVariable", "userDynamicsId", _.get(accountData, "userProfile.dynamicsContactId")]);
            window._mfq.push([
              "setVariable",
              "userMobilePhone",
              _.get(accountData, "userProfile.mobilePhoneNumber", ""),
            ]);
          }
          if (_.get(lenderProfile, "lenderProfile.id", false)) {
            window._mfq.push(["setVariable", "lenderProfileDynamicsId", _.get(lenderProfile, "lenderProfile.id")]);
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    dispatch({ type: SET_LENDER_PROFILE, payload: lenderProfile.lenderProfile });
    mouseflowTrackingData();
  }, [lenderProfile, accountData]);

  // Dealer related users
  useEffect(() => {
    const { relatedUsers } = dealerRelatedUsersData || {};
    if (_.isEmpty(relatedUsers) || !isDealerUser) {
      return;
    }
    setValue("relatedUsers", relatedUsers);
    let formattedRecipients = [];

    //Related Dealers
    formattedRecipients = relatedUsers.map(user => {
      const mobilePhoneNumber = user.mobilePhoneNumber ? user.mobilePhoneNumber.replace(/\D+/g, "") : null;
      const fullName = `${user.firstName} ${user.lastName}${
        user.title ? ` <${user.title}>` : ` <${vendorContactRoleHumanReadable[user.vendorContactRole]}>`
      }`;

      return {
        fullName,
        identity: fullName,
        id: user.id,
        mobilePhoneNumber,
        mugshot: user.mugshot,
        email: user.email,
        isDealer: true,
        isLender: false,
        isApplicant: false,
        category: "Internal",
      };
    });

    //Related Lenders
    if (_.get(accountData, "vendorProfile.lenderProfiles", false)) {
      const lpsFiltered = accountData.vendorProfile.lenderProfiles
        .map(lp => {
          const user = lp.lenderUserProfile;
          if (!user) return;
          const mobilePhoneNumber = user.mobilePhoneNumber ? user.mobilePhoneNumber.trim().replace(/\D+/g, "") : null;
          const fullName = `${user.firstName} ${user.lastName}${user.title ? ` <${user.title}>` : ` <Lender>`}`;

          return {
            fullName,
            identity: fullName,
            id: user.id,
            mobilePhoneNumber,
            email: user.email,
            mugshot: user.mugshot,
            isDealer: false,
            isLender: true,
            isApplicant: false,
            category: "Lenders",
          };
        })
        .filter(x => {
          if (x !== null) {
            return x;
          }
        });
      formattedRecipients.push(...lpsFiltered);
    }

    dispatch({
      type: CHAT_V2_SET_RECIPIENTS_LIST,
      payload: formattedRecipients,
    });
  }, [dealerRelatedUsersData]);

  // Lender related users
  useEffect(() => {
    const { lendersRelatedUsers } = lenderRelatedUsersData || {};
    if (_.isEmpty(lendersRelatedUsers) || !isLenderUser) {
      return;
    }

    const formattedRecipients = lendersRelatedUsers.map(user => {
      const mobilePhoneNumber = user.mobilePhoneNumber ? user.mobilePhoneNumber.replace(/\D+/g, "") : null;
      const fullName = `${user.firstName} ${user.lastName}${
        user.title ? ` <${user.title}>` : ` <${vendorContactRoleHumanReadable[user.vendorContactRole]}>`
      }`;
      const company = user.account.name;
      return {
        fullName,
        identity: fullName,
        id: user.id,
        mobilePhoneNumber,
        mugshot: user.mugshot,
        email: user.email,
        isDealer: true,
        isLender: false,
        isApplicant: false,
        category: "Dealers",
        company,
      };
    });

    dispatch({
      type: CHAT_V2_SET_RECIPIENTS_LIST,
      payload: formattedRecipients,
    });

    dispatch({
      type: SET_RELATED_USERS,
      payload: lendersRelatedUsers,
    });
  }, [lenderRelatedUsersData]);

  useEffect(() => {
    if (chatOpened) {
      window.waitForGlobal("_mfq", function() {
        window._mfq.push(["tag", "chat-open"]);
        window._mfq.push(["newPageView", `/chat-widget`]);
      });
    }
  }, [chatOpened]);

  if (reduxRefetch.account) {
    setRefetch("account", false);
    accountDataRefetch();
  }

  if (reduxRefetch.relatedUsers) {
    setRefetch("relatedUsers", false);
    relatedUsersRefetch();
  }

  //Forced to render as public page
  if (window.location.pathname.indexOf("/terms-and-conditions") !== -1) {
    return <TermsAndConditionsPage />;
  }

  const publicRoute = _.find(publicRoutes, pr => window.location.href.includes(pr.basePath));
  if (!!publicRoute && !currentAuthenticatedUser) {
    return (
      <Router>
        <Switch>
          <Route path={publicRoute.path} component={publicRoute.component} />
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <CommonDataContext.Provider
        value={{
          isDealerUser,
          isLenderUser,
          isCommonPartner,
          isAftermarketPartner,
          isNewVoLayout,
          setIsNewVoLayout,
        }}
      >
        <ViewTypeContextProvider>
          <Theme>
            <CommonModals />

            {window.location.href.includes("/red-flags-report") ? (
              <RedFlagsiFrame />
            ) : (
              <>
                {siteLoading && (
                  <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={siteLoading}>
                    <CircularProgress className={classes.progressLoader} size={48} />
                    <div className={classes.headerContainer}>
                      <img src={logo} className={classes.loadingLogo} />
                    </div>
                  </Backdrop>
                )}
                {accountData && (
                  <UserSessionControl
                    userRole={
                      _.get(accountData, "userProfile.portalType") === "1"
                        ? _.get(accountData, "userProfile.vendorContactRole") || "dealer"
                        : _.get(accountData, "userProfile.lenderContactRole") || "lender"
                    }
                  />
                )}
                {currentAuthenticatedUser === undefined && <div />}
                {currentAuthenticatedUser !== null && currentAuthenticatedUser !== undefined && accountData && (
                  /*                 <Switch>
                    {isDealerUser && (
                      <Dashboard showPrequal={showPrequal} accountData={accountData} dashboards={dashboards} />
                    )}
                    {isLenderUser && <LenderDashboard lenderProfile={lenderProfile} accountData={accountData} />}
                  </Switch> */
                  <Switch>
                    {window.location.pathname === "/constructor" ? (
                      <Constructor accountData={accountData} />
                    ) : (
                      <DashboardV2 />
                    )}
                  </Switch>
                )}

                {currentAuthenticatedUser === null && (
                  <Switch>
                    <AuthLayout
                      currentAuthenticatedUser={currentAuthenticatedUser}
                      onLoginSuccess={user => {
                        setCurrentAuthenticatedUser(user);

                        // setting super property "email"
                        mixpanel.register({
                          email: _.get(user, "attributes.email"),
                        });

                        // remembering that we just logged in. We do not send an event here to grab accountID first.
                        sessionStorage.setItem(LOGGED_IN_FLAG_NAME, true);

                        try {
                          const queryParams = new URLSearchParams(window.location.search);
                          const redirectTo = queryParams.get("redirectTo");
                          if (redirectTo) {
                            window.location.href = redirectTo;
                          } else {
                            window.location.href = "/";
                          }
                        } catch (e) {
                          console.log(e);
                          window.location.href = "/";
                        }
                        //Takes the user to the place that tried to access on the first place
                      }}
                      onLoginError={err => console.log(err)}
                      onPasswordResetSuccess={() => {
                        console.log("Successfully submitted new password");
                        window.location.href = "/";
                      }}
                      onPasswordResetError={err => console.log(err)}
                    />
                  </Switch>
                )}
              </>
            )}
          </Theme>
        </ViewTypeContextProvider>

        <NotificationProvider />
      </CommonDataContext.Provider>
    </Router>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    reduxRefetch: state.refetch,
  };
};

const mapDispatchToProps = {
  setValue,
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
