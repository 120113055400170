import {
  SET_FINANCE_PROGRAMS,
  SET_PROPOSAL_MENUS,
  SELECT_PROPOSALS_PRODUCTS,
  UNSELECT_PROPOSALS_PRODUCTS,
  SET_PROPOSAL_CREATED,
  SET_MENU_RESULTS,
  SET_MENU_OPTIONS,
  RESET_MENU_BUILDER,
  TOGGLE_PROPOSALS_PRODUCTS_LOADING,
  REMOVE_PRODUCT,
  UPDATE_PRODUCT,
  SET_CALCULATING,
  ADD_PRODUCT,
  UPDATE_RECALCULATE,
  UPDATE_PROPOSALS_PRODUCTS,
  SET_PROPOSALS_PRODUCTS,
  LAYOUT_UPDATE_SECTION_IS_OPEN,
  LAYOUT_SELECT_MENU_OPTION,
  SET_EQUIPMENT_DATA,
  RESET_STRUCTURE_AND_MENU_OPTIONS,
  REMOVE_PRODUCT_ADDON,
  CLEAR,
  SET_MENU_TERM,
  SET_IS_NEED_TO_AUTO_FILL_PRODUCTS_DYNAMIC_FIELDS,
  SET_CURRENT_EQUIPMENT_DATA,
} from "./actionTypes";
import { DeskingState } from "./types";
import { FormSections } from "../types";

const initialState: DeskingState = {
  recalculateStep: 0,
  isProposalCreated: false,
  isNeedAutoFillProposalProductsFields: 0,
  financePrograms: {},
  proposalMenus: {},
  term: { index: 0, term: "" },
  proposalProducts: {
    products: [],
    isLoading: false,
    selectedProducts: {},
  },
  menuBuilder: {},
  layout: {
    sections: {
      [FormSections.MenuBuilder]: {
        isOpen: false,
      },
    },
    selectedMenuOptions: null,
  },
  equipment: {
    all: [],
    current: null,
  },
  calculateMultiplePayments: [],
};

export const deskingReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_PROPOSALS_PRODUCTS:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          products: payload,
        },
      };

    case TOGGLE_PROPOSALS_PRODUCTS_LOADING:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          isLoading: payload,
        },
      };

    case UPDATE_RECALCULATE:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
      };

    case SET_EQUIPMENT_DATA:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          all: payload,
          current: payload[0],
        },
      };

    case SET_CURRENT_EQUIPMENT_DATA:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          current: state.equipment.all.find(equipment => equipment.equipmentId === payload) ?? null,
        },
      };

    case SET_FINANCE_PROGRAMS:
      return {
        ...state,
        financePrograms: payload.reduce((acc: any, program: any) => {
          acc[program.financeProgramId] = program;
          return acc;
        }, {}),
      };

    case SET_PROPOSAL_MENUS:
      return {
        ...state,
        proposalMenus: payload.reduce((acc: any, program: any) => {
          acc[program.proposalMenuId] = program;
          return acc;
        }, {}),
      };

    case RESET_MENU_BUILDER:
      return {
        ...state,
        calculateMultiplePayments: [],
        menuBuilder: {},
      };

    case UPDATE_PROPOSALS_PRODUCTS:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          selectedProducts: payload.reduce((acc: any, product: any) => {
            acc[product.proposalProductId] = product;
            return acc;
          }, {}),
        },
      };

    case SELECT_PROPOSALS_PRODUCTS:
      return {
        ...state,
        isNeedAutoFillProposalProductsFields: state.isNeedAutoFillProposalProductsFields + 1,
        proposalProducts: {
          selectedProducts: {
            ...state.proposalProducts.selectedProducts,
            [payload.proposalProductId]: payload,
          },
        },
      };

    case UNSELECT_PROPOSALS_PRODUCTS:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          selectedProducts: Object.entries(state.proposalProducts.selectedProducts).reduce<any>((acc, [key, value]) => {
            if (key === payload) return acc;
            acc[key] = value;
            return acc;
          }, {}),
        },
      };

    case SET_PROPOSAL_CREATED:
      return {
        ...state,
        isProposalCreated: payload,
      };

    case SET_MENU_RESULTS:
      return {
        ...state,
        menuBuilder: payload,
      };

    case SET_MENU_TERM:
      return {
        ...state,
        term: {
          ...state.term,
          ...payload,
        },
      };

    case SET_MENU_OPTIONS:
      return {
        ...state,
        menuBuilder: Object.entries(state.menuBuilder).reduce<Record<string, any>>((acc, [term, builder]) => {
          acc[term] = {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...builder,
              menuOptions: payload,
            },
          };

          return acc;
        }, {}),
      };

    case ADD_PRODUCT:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        menuBuilder: Object.entries(state.menuBuilder).reduce<Record<string, any>>((acc, [term, builder]) => {
          acc[term] = {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...builder,
              menuOptions: builder.menu.menuOptions.map((option: any) => {
                if (option.name === payload.menuType) {
                  // try {
                  //   payload.product.aftermarketProduct.configuration = { ...payload.product.configuration };
                  // } catch (e: any) {
                  //   console.error(e);
                  // }
                  return {
                    ...option,
                    products: [...option.products, payload.product],
                  };
                }

                return option;
              }),
            },
          };

          return acc;
        }, {}),
      };

    case REMOVE_PRODUCT:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        menuBuilder: {
          ...state.menuBuilder,
          [state.term.term]: {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...state.menuBuilder[state.term.term].menu,
              menuOptions: state.menuBuilder[state.term.term].menu.menuOptions.map((option: any) => {
                if (option.name === payload.menuType) {
                  return {
                    ...option,
                    products: option.products.filter((product: any, index: number) => index !== payload.removeIndex),
                  };
                }

                return option;
              }),
            },
          },
        },
      };

    case REMOVE_PRODUCT_ADDON:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        menuBuilder: {
          ...state.menuBuilder,
          [state.term.term]: {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...state.menuBuilder[state.term.term].menu,
              menuOptions: state.menuBuilder[state.term.term].menu.menuOptions.map((option: any) => {
                if (option.name === payload.menuType) {
                  return {
                    ...option,
                    products: option.products.map((product: any, index: number) => {
                      if (product.proposalProductId !== payload.productId || index !== payload.productIndex) {
                        return product;
                      }

                      return {
                        ...product,
                        addons: product.addons?.filter((addon: any) => addon.title !== payload.title),
                        configuration: {
                          ...product?.configuration,
                          addons: product?.configuration?.addons?.filter(
                            (addon: any, index: number) => addon.title !== payload.title
                          ),
                          removedAddon: payload.title,
                        },
                      };
                    }),
                  };
                }

                return option;
              }),
            },
          },
        },
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        menuBuilder: {
          ...state.menuBuilder,
          [state.term.term]: {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...state.menuBuilder[state.term.term].menu,
              menuOptions: state.menuBuilder[state.term.term].menu.menuOptions.map((option: any) => {
                if (option.name === payload.menuName) {
                  return {
                    ...option,
                    products: option.products.map((product: any) => {
                      if (product.proposalProductId !== payload.productId) return product;

                      return {
                        ...product,
                        ...(payload.updateValues ?? {}),
                      };
                    }),
                  };
                }

                return option;
              }),
            },
          },
        },
      };

    case LAYOUT_UPDATE_SECTION_IS_OPEN:
      return {
        ...state,
        layout: {
          ...state.layout,
          sections: {
            ...state.layout.sections,
            [payload.section]: {
              ...(state.layout.sections?.[payload as FormSections] ?? {}),
              isOpen: payload.isOpen,
            },
          },
        },
      };

    case LAYOUT_SELECT_MENU_OPTION:
      return {
        ...state,
        layout: {
          ...state.layout,
          selectedMenuOptions: state.layout.selectedMenuOptions === payload ? null : payload,
        },
      };

    case CLEAR:
      return initialState;

    case RESET_STRUCTURE_AND_MENU_OPTIONS:
      return {
        ...initialState,
        proposalProducts: {
          products: state.proposalProducts.products,
          selectedProducts: [],
        },
        equipment: state.equipment,
        proposalMenus: state.proposalMenus,
        financePrograms: state.financePrograms,
      };

    case SET_CALCULATING:
      return {
        ...state,
        calculateMultiplePayments: payload,
      };

    case SET_IS_NEED_TO_AUTO_FILL_PRODUCTS_DYNAMIC_FIELDS:
      return {
        ...state,
        isNeedAutoFillProposalProductsFields: state.isNeedAutoFillProposalProductsFields + 1,
      };

    default:
      return state;
  }
};
