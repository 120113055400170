import React from "react";
import { Column } from "react-table";
import { Payment } from "@trnsact/trnsact-shared-types";
import { CellArgs } from "../../../types";
import { SimpleTextCell } from "../cells";
import { formatCurrency } from "../../../../../utils";
import { MenuCell, ProductsCell, FinanceProgramCell, DetailsActionCell } from "./cells";

type DetailsProposalCellArgs = CellArgs<Payment>;

interface Options {
  onOpenFinanceProgramDialog: VoidFunction;
  onOpenDetailsDialog: (details: any) => void;
}

export function createTableColumns({ onOpenDetailsDialog, onOpenFinanceProgramDialog }: Options): Column<Payment>[] {
  return [
    {
      Header: "Term",
      accessor: "term",
      Cell: ({ original }: DetailsProposalCellArgs) => <SimpleTextCell value={original.term!} />,
    },
    {
      Header: "Rate",
      accessor: "paymentCriteria.rate",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <SimpleTextCell formatter={value => (value ? `${value}%` : "0.00%")} value={original.paymentCriteria.rate} />
      ),
    },
    {
      Header: "Monthly Payment",
      style: { minWidth: "9rem" },
      headerStyle: { minWidth: "9rem" },
      accessor: "vendorOpportunity.salesRepresentative.fullName",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <SimpleTextCell
          formatter={formatCurrency}
          value={
            original.paymentCriteriaSelected?.menuOption?.payment
              ? original.paymentCriteriaSelected.menuOption.payment.paymentAmountPerPeriod
              : original.payment
          }
        />
      ),
    },
    {
      Header: "Finance Program",
      style: { minWidth: "9rem" },
      headerStyle: { minWidth: "9rem" },
      accessor: "paymentCriteria.financeProgramName",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <FinanceProgramCell details={original} onOpenFinanceProgramDialog={onOpenFinanceProgramDialog} />
      ),
    },
    {
      Header: "Dealer Markup (%)",
      style: { minWidth: "9rem" },
      headerStyle: { minWidth: "9rem" },
      accessor: "paymentCriteria.menu.menuOptions[0].payment.dealerMarkup",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <SimpleTextCell
          formatter={value => (value ? `${value}%` : "0.00%")}
          value={original.paymentCriteria.menu?.menuOptions[0]?.payment?.dealerMarkup}
        />
      ),
    },
    {
      Header: "Profit (%)",
      accessor: "paymentCriteria.menu.menuOptions[0].payment.profitPercentage",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <SimpleTextCell
          formatter={value => (value ? `${value}%` : "0.00%")}
          value={original.paymentCriteria.menu?.menuOptions[0]?.payment?.profitPercentage}
        />
      ),
    },
    {
      Header: "Menu",
      style: { minWidth: "13rem" },
      headerStyle: { minWidth: "13rem" },
      accessor: "paymentCriteria.menu.name",
      Cell: ({ original }: DetailsProposalCellArgs) => <MenuCell details={original} />,
    },
    {
      Header: "Product(s)",
      headerStyle: { minWidth: "25rem" },
      style: { minWidth: "25rem", textAlign: "start" },
      accessor: "vendorOpportunity.financeManager.fullName",
      Cell: ({ original }: DetailsProposalCellArgs) => <ProductsCell details={original} />,
    },
    {
      Header: "Action",
      accessor: "vendorOpportunity.financeManager.fullName",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <DetailsActionCell details={original} onOpenDetailsDialog={onOpenDetailsDialog} />
      ),
    },
  ].map(header => ({
    ...header,
    sortable: false,
    resizable: false,
    headerClassName: "customHeader",
    headerStyle: { minHeight: "2.5rem", ...(header?.headerStyle ?? {}) },
  }));
}
