import React, { Fragment, ReactNode, useContext } from "react";
import { CommonDataContext } from "../../../contexts/CommonDataContext";
import PageOpportunityDetails_V2 from "./PageOpportunityDetails_V2";
import PageOpportunityDetails from "../PageOpportunityDetails";
import { useViewTypeContext } from "../../../contexts/contentViewType";
import { ModalsKeys } from "../../../global";
import { FinanceProgramModal, PreviewFinanceProgramModal } from "../../../modules/financeProgram";
import { ModalsContainer } from "../../../components/shared/Modals";

export const PageOpportunityDetailsContainer = ({ match }: any) => {
  const { isNewVoLayout } = useContext(CommonDataContext);
  const { isMobile } = useViewTypeContext();

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.FinanceForm]: <FinanceProgramModal />,
    [ModalsKeys.PreviewFinanceForm]: <PreviewFinanceProgramModal />,
  };

  return (
    <>
      {isNewVoLayout || isMobile ? (
        <PageOpportunityDetails_V2 match={match} />
      ) : (
        <PageOpportunityDetails match={match} />
      )}

      <ModalsContainer modalsWithKeys={modalsWithKeys} />
    </>
  );
};
