import React, { useEffect } from "react";
import { FormInputSelect } from "../../../../../../../components/form";
import { formatCurrency, formatNumberWithDelimiter } from "../../../../../../../utils";
import { useSelector } from "react-redux";
import { deskingSelectors } from "modules/desking/model";

interface Props {
  configuration: Record<"addons" | "coverage" | "deductible", any>;
  uniqueDeductibles: any[];
  handleMileageChange: any;
  disableAllFields: boolean;
  handleDeductibleChange: any;
  filteredMileageOptions: any[];
}

export const Fields = ({
  configuration,
  disableAllFields,
  uniqueDeductibles,
  handleMileageChange,
  filteredMileageOptions,
  handleDeductibleChange,
}: Props) => {
  const currentTerm = useSelector(deskingSelectors.term);

  const handleChangeDeductible = (value: string) => {
    const selectedAmount = parseInt(value, 10);
    handleDeductibleChange(selectedAmount);
  };

  const handleChangeCoverage = (value: string) => {
    const selectedIndex = parseInt(value, 10);
    const selectedTerm = filteredMileageOptions[selectedIndex];
    handleMileageChange(selectedTerm);
  };

  useEffect(() => {
    if (uniqueDeductibles?.length > 0) {
      const selectedAmount = uniqueDeductibles[0];
      handleChangeDeductible(selectedAmount);
    }
  }, []);

  useEffect(() => {
    if (filteredMileageOptions?.length > 0) {
      const parsedCurrentTerm = parseInt(currentTerm.term, 10);
      const closestMileageIndex = filteredMileageOptions?.reduce((closestIndex, item, index) => {
        const closestDiff = Math.abs(filteredMileageOptions[closestIndex].termMonths - parsedCurrentTerm);
        const currentDiff = Math.abs(item.termMonths - parsedCurrentTerm);

        return currentDiff < closestDiff ? index : closestIndex;
      }, 0);
      handleChangeCoverage(closestMileageIndex.toString());
    }
  }, [filteredMileageOptions?.length]);

  return (
    <>
      <FormInputSelect
        variant="standard"
        label="Deductible"
        disabled={disableAllFields}
        onChange={handleChangeDeductible}
        value={configuration.deductible || configuration.deductible === 0 ? configuration.deductible.toString() : ""}
        options={[
          { label: "Select Deductible", value: "" },
          ...uniqueDeductibles.map((amount: any) => ({
            label: formatCurrency(amount, true),
            value: amount!.toString(),
          })),
        ]}
      />

      {(configuration.deductible || configuration.deductible === 0) && (
        <FormInputSelect
          label="Coverage"
          variant="standard"
          disabled={disableAllFields}
          onChange={handleChangeCoverage}
          value={configuration?.coverage ? filteredMileageOptions?.findIndex((c) => {
            return c.dealerRemit === configuration.coverage.dealerRemit &&
              c.dealerCost === configuration.coverage.dealerCost &&
              c.termMonths === configuration.coverage.termMonths &&
              c.termOdometer === configuration.coverage.termOdometer;
          }) : ""}
          options={[
            { label: "Select Coverage", value: "" },
            ...(filteredMileageOptions || []).map((term: any, index: any) => ({
              label: `${term.termMonths} Months / ${formatNumberWithDelimiter(term.termOdometer)} Miles`,
              value: index.toString(),
            })),
          ]}
        />
      )}
    </>
  );
};
