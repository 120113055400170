import { Payment } from "@trnsact/trnsact-shared-types";

export function prepareLenderPaymentOptionsForRender(payments: Payment[]) {
  return payments.reduce<Payment[]>((acc, paymentOption) => {
    if (paymentOption.paymentCriteriaSelected) {
      acc.push(paymentOption);
    } else {
      paymentOption.paymentCriteria.menu.menuOptions.forEach((options: any) => {
        acc.push({
          ...paymentOption,
          paymentCriteria: {
            ...paymentOption.paymentCriteria,
            menu: {
              ...paymentOption.paymentCriteria.menu,
              menuOption: options,
            },
          },
        });
      });
    }

    return acc;
  }, []);
}
