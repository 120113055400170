import { FormSections } from "../types";
import { capitalizeFirstLetter } from "../../../formatters";

// Finance program

const financePrograms = (state: any) => state.desking.financePrograms;
const financeProgramById = (state: any, id: string | undefined) => (id ? state.desking.financePrograms?.[id] : null);
const financeProgramConfigById = (state: any, id: string | undefined) =>
  id ? state.desking.financePrograms?.[id]?.paymentOptionsConfiguration : null;

const financeProgramsSelectors = (state: any) =>
  Object.values(state.desking.financePrograms).map((program: any) => ({
    label: program.nameInternal,
    value: program.financeProgramId,
  }));

// Proposal menu

const term = (state: any) => state.desking.term;

const proposalMenus = (state: any) => state.desking.proposalMenus ?? {};
const proposalMenuById = (state: any, id: string | undefined) => (id ? state.desking.proposalMenus?.[id] : null);
const proposalMenuByCurrentTerm = (state: any) => state.desking.menuBuilder?.[state.desking.term?.term] ?? null;

const menuByTerm = (state: any) => state.desking.menuBuilder?.[state.desking.term?.term]?.menu?.menuOptions ?? [];
const menuByTerms = (state: any) => state.desking.menuBuilder;

const selectedMenuOption = (state: any) => {
  let menuOptions = menuByTerm(state);

  if (menuOptions.length === 0) {
    menuOptions = state.aftermarketMenuConstructor?.proposalMenu?.menuOptions;
  }

  let selectedMenuOptionName: string;

  if (state.desking?.layout?.selectedMenuOptions) {
    selectedMenuOptionName = state.desking.layout.selectedMenuOptions;
  } else if (state.aftermarketMenuConstructor?.selectedMenuOption) {
    selectedMenuOptionName = state.aftermarketMenuConstructor.selectedMenuOption.name;
  }

  const menuOption = menuOptions.find((option: any) => option?.name === selectedMenuOptionName) ?? "";

  if (menuOption) {
    return {
      ...menuOption,
      products: menuOption.products.map((product: any) => {
        const foundedProposalProduct = state.desking.proposalProducts.products.find(
          (proposalProduct: any) =>
            proposalProduct.selectedProductId === product.selectedProductId && !!proposalProduct.selectedProductId
        );

        if (foundedProposalProduct) {
          return {
            ...product,
            aftermarketProduct: foundedProposalProduct?.aftermarketProduct,
          };
        }

        return product;
      }),
    };
  }

  return menuOption;
};

// Proposals products

const selectedProposalsProducts = (state: any) => state.desking.proposalProducts.selectedProducts;
const selectedProposalsProductsIds = (state: any) => Object.keys(state.desking.proposalProducts.selectedProducts);
const selectedProposalsProductsArray = (state: any) => Object.values(state.desking.proposalProducts.selectedProducts);

const menuOptionsGroupedByName = (state: any) => {
  const menuOption = menuByTerm(state);

  return (
    menuOption.reduce((acc: any, option: any) => {
      acc[option.name] = option;

      return acc;
    }, {}) ?? {}
  );
};

const isProposalsProductsLoading = (state: any) => state.desking.proposalProducts.isLoading;
const proposalsProducts = (state: any) => state.desking.proposalProducts.products;
const proposalsProductsIds = (state: any) =>
  state.desking.proposalProducts.products.map((product: any) => product.proposalProductId);

const proposalCreated = (state: any) => state.desking.isProposalCreated;

const productConfiguration = (state: any, productId: string) => {
  const menuOption = selectedMenuOption(state);

  const product = menuOption?.products?.find((product: any) => product?.proposalProductId === productId);

  if (product?.aftermarketProduct?.config?.criteriaValues?.length > 0) {
    return product?.aftermarketProduct?.config?.criteriaValues[0];
  }

  return product?.configuration ?? null;
};

// Other

const recalculateStep = (state: any) => state.desking.recalculateStep;
const isSectionOpen = (state: any, section: FormSections) => state.desking.layout.sections?.[section]?.isOpen ?? true;
const sectionsStatuses = (state: any, sections: FormSections[]) =>
  sections.reduce<Partial<Record<FormSections, boolean>>>((acc, section) => {
    acc[section] = state.desking.layout.sections?.[section]?.isOpen ?? true;
    return acc;
  }, {});
const equipment = (state: any) => state.desking.equipment.all;
const currentEquipment = (state: any) => state.desking.equipment.current;
const calculateMultiplePayments = (state: any) => state.desking.calculateMultiplePayments;
const qualifyResult = (state: any) => state.desking.qualifyProcessResult;
const equipmentDataById = (state: any, id: string) =>
  state.desking.equipment?.all?.find((equipment: any) => equipment.equipmentId === id);
const equipmentSelectOptions = (state: any) =>
  state.desking.equipment?.all?.map((equipment: any) => ({
    label: [
      capitalizeFirstLetter(equipment?.newUsed ?? ""),
      equipment?.year,
      equipment?.make,
      equipment?.model,
      equipment?.serialNumberVin,
      equipment?.mileage,
    ]
      .filter(Boolean)
      .join(" "),
    value: equipment.equipmentId,
  })) ?? [];

const isNeedAutoFillProposalProductsFields = (state: any) => state.desking.isNeedAutoFillProposalProductsFields;

export const deskingSelectors = {
  term,
  equipment,
  menuByTerm,
  menuByTerms,
  currentEquipment,
  qualifyResult,
  isSectionOpen,
  financePrograms,
  proposalCreated,
  proposalMenus,
  recalculateStep,
  proposalMenuById,
  proposalsProducts,
  proposalsProductsIds,
  equipmentDataById,
  menuOptionsGroupedByName,
  financeProgramById,
  selectedMenuOption,
  productConfiguration,
  equipmentSelectOptions,
  financeProgramConfigById,
  financeProgramsSelectors,
  selectedProposalsProducts,
  calculateMultiplePayments,
  proposalMenuByCurrentTerm,
  sectionsStatuses,
  isProposalsProductsLoading,
  selectedProposalsProductsIds,
  selectedProposalsProductsArray,
  isNeedAutoFillProposalProductsFields,
};
