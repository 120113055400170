import React, { Fragment, ReactNode, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid, Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { DealerManagementTable } from "./tables/DealerManagementTable";
import { AftermarketPartnerDealer } from "modules/aftermarketPartner/types";
import { ModalsKeys } from "global";
import { useModal } from "hooks/useModal";
import { AddDealerDialog } from "../../dialogs/AddDealerDialog";
import { modalsSelectors } from "redux/modalsReducer";
import { GET_PARTNER_LINKS } from "modules/aftermarketPartner/api";
import { PartnerLinksResponse } from "modules/aftermarketPartner/api/types";
import { aftermarketPartnerActions, aftermarketPartnerSelectors } from "modules/aftermarketPartner/model";

export const AftermarketPartnerDealerManagement = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.account);
  const partnerLinks = useSelector(aftermarketPartnerSelectors.partnerLinks);
  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];

  const { handleOpen: handleAddDealerDialogOpen } = useModal(ModalsKeys.AftermarketPartnerAddDealerDialog);

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.AftermarketPartnerAddDealerDialog]: <AddDealerDialog />,
  };

  const { loading: loadingPartnerLinks } = useQuery<PartnerLinksResponse>(GET_PARTNER_LINKS, {
    skip: !account.dynamicsAccountId,
    variables: {
      dynamicsId: account.dynamicsAccountId,
    },
    onCompleted(data) {
      console.log(data);
      dispatch(aftermarketPartnerActions.setPartnerLinks(data.getPartnerLinks));
    },
  });

  /*   const mockData: AftermarketPartnerDealer[] = [
    {
      name: "Test",
      status: "Active",
      activeMenus: "",
      recentQuotes: 0,
      recentContracts: 0,
      productsSold: 0,
      totalVolume: 0,
    },
    {
      name: "Another test",
      status: "Invited",
      activeMenus: "",
      recentQuotes: 0,
      recentContracts: 0,
      productsSold: 0,
      totalVolume: 0,
    },
  ]; */

  return (
    <>
      <CardContainer
        title="Dealer Management"
        actionBtn={
          <Button variant="contained" size="small" startIcon={<AddIcon />} onClick={() => handleAddDealerDialogOpen()}>
            Add Dealer
          </Button>
        }
      >
        <Box className={classes.container}>
          <DealerManagementTable loading={loadingPartnerLinks} tableData={partnerLinks} />
        </Box>
      </CardContainer>

      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  container: {},
}));
