import React from "react";
import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import { SelectOption } from "../../global";
import { makeStyles } from "@material-ui/core/styles";

interface FormInputSelectProps<
  ValueType extends string | number = string,
  Options extends SelectOption<ValueType> = SelectOption<ValueType>
> extends Omit<TextFieldProps, "select" | "onChange"> {
  label?: string;
  errorText?: string;
  options?: Options[];
  isLoading?: boolean;
  onChange: (value: ValueType) => void;
}

export const FormInputSelect = <
  ValueType extends string | number = string,
  Options extends SelectOption<ValueType> = SelectOption<ValueType>
>({
  label,
  value,
  error,
  options,
  onChange,
  errorText,
  isLoading,
  ...rest
}: FormInputSelectProps<ValueType, Options>) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    event.preventDefault();
    event.stopPropagation();

    onChange(event.target.value as ValueType);
  };

  return (
    <TextField
      select
      fullWidth
      size="small"
      label={label}
      value={value}
      error={error}
      variant="outlined"
      onChange={handleChange}
      className={classes.requiredInput}
      SelectProps={{
        ref: rest.inputRef,
        MenuProps: {
          getContentAnchorEl: null,
          style: { maxHeight: "500px" },
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        },
      }}
      helperText={error ? errorText : ""}
      disabled={rest?.inputProps?.disabled}
      {...rest}
    >
      {options?.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const useStyles = makeStyles({
  requiredInput: {
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
  },
});
