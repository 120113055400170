import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deskingActions, deskingSelectors } from "../model";

export function useMenuOptionTerms() {
  const dispatch = useDispatch();

  const currentMenuTerm = useSelector(deskingSelectors.term);
  const groupedMenuOptionsByTerm = useSelector(deskingSelectors.menuByTerms);

  const termsOptions = useMemo(() => {
    const menuTerms = Object.keys(groupedMenuOptionsByTerm);

    const nextMenuTermValue = menuTerms[menuTerms.findIndex(term => term === currentMenuTerm.term) + 1];
    const backMenuTermValue = menuTerms[menuTerms.findIndex(term => term === currentMenuTerm.term) - 1];

    const isNeedSyncTermAndIndex = menuTerms[currentMenuTerm.index] !== currentMenuTerm.term;

    if (isNeedSyncTermAndIndex) {
      dispatch(
        deskingActions.setMenuTerm({
          term: currentMenuTerm.term,
          index: menuTerms.findIndex(term => term === currentMenuTerm.term),
        })
      );
    }

    const isCurrentTermExistInTerms = menuTerms.includes(currentMenuTerm.term);

    if (!isCurrentTermExistInTerms) {
      const lastTermIndex = menuTerms.length - 1;

      dispatch(
        deskingActions.setMenuTerm({
          index: lastTermIndex,
          term: menuTerms[lastTermIndex],
        })
      );
    }

    return {
      isBackwardDisabled: currentMenuTerm.index <= 0,
      isForwardDisabled: menuTerms.length <= currentMenuTerm.index + 1,
      forwardTooltip: nextMenuTermValue ? `Go to ${nextMenuTermValue}` : "",
      backwardTooltip: backMenuTermValue ? `Back to ${backMenuTermValue}` : "",
    };
  }, [currentMenuTerm, groupedMenuOptionsByTerm]);

  const handleUpdateTerm = useCallback(
    (action: "add" | "sub") => {
      const actionMapper = {
        add: currentMenuTerm.index + 1,
        sub: currentMenuTerm.index - 1,
      };

      dispatch(
        deskingActions.setMenuTerm({
          index: actionMapper[action],
          term: Object.keys(groupedMenuOptionsByTerm)[actionMapper[action]],
        })
      );
    },
    [dispatch, groupedMenuOptionsByTerm, currentMenuTerm]
  );

  return {
    currentTerm: currentMenuTerm,
    ...termsOptions,
    handleUpdateTerm,
  };
}
