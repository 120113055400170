import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { AftermarketProductCategory } from "@trnsact/trnsact-shared-types";

interface Props {
  productCategory: AftermarketProductCategory;
}

export const ProposalProductsCardTypeChip = ({ productCategory }: Props) => {
  const classes = useStyles();

  const chipClassByType = {
    [AftermarketProductCategory.Gap]: classes.gap,
    [AftermarketProductCategory.Roadside]: classes.roadside,
    [AftermarketProductCategory.Warranty]: classes.warranty,
    [AftermarketProductCategory.Insurance]: classes.insurance,
  };

  return <Chip size="small" label={productCategory} className={chipClassByType?.[productCategory] ?? ""} />;
};

const useStyles = makeStyles(() => ({
  insurance: {
    backgroundColor: "#4E89F3",
    color: "#FFFFFF",
  },
  gap: {
    backgroundColor: "#F564A9",
    color: "#FFFFFF",
  },
  roadside: {
    backgroundColor: "#3CC37A",
    color: "#FFFFFF",
  },
  warranty: {
    backgroundColor: "#FFB64E",
    color: "#FFFFFF",
  },
}));
