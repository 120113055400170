import { FinanceProgramFormValues, MarkupMethods, Structures } from "../types";
import { prepareMenuOptionsForAPI } from "./prepareMenuOptionsForAPI";

export function collectDataForCreateProposal(
  formData: FinanceProgramFormValues,
  menuByTerms: Record<string, any>
): Record<string, unknown> {
  const {
    financeQuote: {
      fee,
      terms,
      amount,
      rateType,
      commonRate,
      downPayment,
      programName,
      specifyRate,
      specifyRates,
      advancePayment,
      markupRate,
      residual,
      paymentCalculationMethod,
      programType,
      structure,
      markupMethod,
    },
    financeProgramDetails,
  } = formData;

  return {
    salesTaxPercentage: 0,
    numberOfAdvancePayments: Number(advancePayment) ?? 0,
    financeProgramType: programType.toUpperCase(),
    rateType,
    amountFinanced: Number(amount) ?? 0,
    financeProgramName: programName,
    downPaymentAmount: Number(downPayment) ?? 0,
    residualPercentage: Number(residual) ?? 0,
    menu: prepareMenu(menuByTerms, financeProgramDetails, formData.financeQuote.menu),
    customerRate:
      structure !== Structures.None && markupMethod === MarkupMethods.CustomerRate ? Number(markupRate) : null,
    dealerMarkup:
      structure !== Structures.None && markupMethod === MarkupMethods.Markup
        ? { identifier: "Dealer Markup", markupPercentage: Number(markupRate) }
        : null,
    paymentPeriodTiming: paymentCalculationMethod,
    markups: [],
    terms: prepareRates({ specifyRate, commonRate, specifyRates, terms }),
    flatFees: fee.map(feeValue => ({
      identifier: feeValue.identifier,
      financed: feeValue.financed,
      fee: Number(feeValue.fee),
    })),
  };
}

function prepareMenu(
  menuByTerms: Record<string, any> = {},
  financeProgramDetails: Record<string, any> = [],
  menuId: string = ""
) {
  const menuArr = Object.values(menuByTerms);

  if (!menuArr.length) return null;

  return {
    name: menuArr[0].menu.name,
    proposalMenuId: menuId,
    menuOptions: prepareMenuOptionsForAPI(menuArr[0]?.menu?.menuOptions, financeProgramDetails),
  };
}

function prepareRates({
  terms,
  specifyRate,
  commonRate,
  specifyRates,
}: Pick<FinanceProgramFormValues["financeQuote"], "specifyRates" | "commonRate" | "specifyRate" | "terms">) {
  if (!specifyRate) return terms.map(term => ({ term: Number(term), rate: Number(commonRate) }));
  return specifyRates.map(({ rate, term }) => ({ term: Number(term), rate: Number(rate) }));
}
